<template>
  <div class="card">
    <!-- LOADING COMPONENTE -->
    <span id="loading" v-if="this.loading == true">
      <Loading />
    </span>
    <h3>DHL - {{ this.order[0].id }}</h3>
    <hr />
    <!-- FORMULÁRIO DO ENVIO DA DHL -->
    <form v-on:submit.prevent="submit()">
      <div class="row">
        <div class="col">
          <!-- NOME DO DESTINATÁRIO -- NÃO EDITÁVEL -->
          <label for="firstName">Primerio Nome:</label>
          <input
            id="firstName"
            type="text"
            class="form-control"
            v-model="firstName"
          />
        </div>
        <div class="col">
          <!-- APELIDO DO DESTINATÁRIO -- NÃO EDITÁVEL -->
          <label for="secondName">Apelido:</label>
          <input
            id="secondname"
            type="text"
            class="form-control"
            v-model="secondName"
          />
        </div>
        <div class="col">
          <!-- EMAIL DO DESTINATÁRIO -- NÃO EDITÁVEL -->
          <label for="email">Email:</label>
          <input
            id="email"
            type="text"
            class="form-control"
            :placeholder="this.order[0].email"
            disabled
          />
        </div>
      </div>
      <div class="row">
        <hr />
        <a id="aUpdateAddress" @click="translate(finalAdress, city, state)"
          >🌐 Traduzir 🌐</a
        >
        <div class="col">
          <!-- MORADA DO DESTINATÁRIO -- NÃO EDITÁVEL -->
          <label for="adress">Morada:</label>
          <input
            id="adress"
            type="text"
            class="form-control"
            v-model="finalAdress"
          />
        </div>
        <div class="col">
          <!-- CIDADE DO DESTINATÁRIO -- NÃO EDITÁVEL -->
          <label for="city">Cidade:</label>
          <input id="city" type="text" class="form-control" v-model="city" />
        </div>
        <div class="col">
          <!-- CIDADE DO DESTINATÁRIO -- NÃO EDITÁVEL -->
          <label for="city">Estado:</label>
          <input id="city" type="text" class="form-control" v-model="state" />
        </div>
        <div class="col">
          <!-- PAÍS DO DESTINATÁRIO -- NÃO EDITÁVEL -->
          <label for="country">País:</label>
          <input
            id="country"
            type="text"
            class="form-control"
            :placeholder="country"
            disabled
          />
          <a href="/" id="aUpdateAddress" @click="allCountries()"
            >✒️ Editar País</a
          >
        </div>
      </div>
      <div class="row">
        <div class="col">
          <!-- TELEFONE DO DESTINATÁRIO -- EDITÁVEL -->
          <label for="postalCode">Código Postal:</label>
          <div class="input-group mb-2">
            <input
              id="postalCode"
              type="text"
              class="form-control"
              v-model="postalCode"
              required
            />
          </div>
        </div>
        <div class="col">
          <!-- TELEFONE DO DESTINATÁRIO -- NÃO EDITÁVEL -->
          <label for="total">Contacto Telefónico:</label>
          <div class="input-group mb-2">
            <input
              id="total"
              type="text"
              class="form-control"
              v-model="phone"
            />
          </div>
        </div>
        <div class="col">
          <!-- MOEDA DO DESTINATÁRIO -- NÃO EDITÁVEL -->
          <label for="total">Total ({{ this.order[0].coin }}):</label>
          <input
            id="total"
            type="text"
            class="form-control"
            :placeholder="this.order[0].total"
            disabled
          />
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col">
          <!-- MATERIAIS DE ENVIO (caixas interativas) -->
          <label>Materiais de Envio:</label>
          <div class="material-container">
            <div
              v-for="material in productTranMaterial"
              :key="material.sku"
              :class="[
                'material-box',
                { selected: selectedMaterials.includes(material.sku) },
              ]"
              @click="toggleMaterial(material.sku)"
            >
              {{ material.name }}
            </div>
          </div>
        </div>
      </div>
      <!-- Adicionando a mensagem gerada -->
      <div class="row mt-3">
        <div class="col">
          <div v-if="finalMessage != ''" class="alert alert-info">
            <div v-html="finalMessage"></div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <!-- ALTURA DA CAIXA DE ENVIO -- EDITÁVEL -->
          <label for="height">Altura (cm):</label>
          <input
            id="height"
            type="number"
            class="form-control"
            v-model="height"
            required
          />
        </div>
        <div class="col">
          <!-- LARGURA DA CAIXA DE ENVIO -- EDITÁVEL-->
          <label for="length">Largura (cm):</label>
          <input
            id="length"
            type="number"
            class="form-control"
            v-model="length"
            required
          />
        </div>
        <div class="col">
          <!-- COMPRIMENTO DA CAIXA DE ENVIO -- EDITÁVEL -->
          <label for="length">Comprimento (cm):</label>
          <input
            id="length"
            type="number"
            class="form-control"
            v-model="width"
            required
          />
        </div>
        <div class="col">
          <!-- PESO DA ENCOMENDA -- NÃO EDITÁVEL -->
          <label for="weigth">Peso (Kg):</label>
          <input
            id="length"
            type="text"
            class="form-control"
            v-model="weigthInput"
          />
          <a style="color: red">Peso esperado:</a>
          {{ this.order[0].expected_weight }} Kg
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label for=""> Peso Volumétrico:</label>
          <span
            v-if="
              (this.length * this.width * this.height) / 5000 > this.weigthInput
            "
            style="color: red"
          >
            {{ (this.length * this.width * this.height) / 5000 }} KG
          </span>
          <span v-else>
            {{ (this.length * this.width * this.height) / 5000 }} KG
          </span>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <!-- PEQUENA DESCRIÇÃO DO ENVIO_ex:nºencomenda -- EDITÁVEL -->
          <label for="length">Descrição:</label>
          <input
            id="length"
            type="text"
            class="form-control"
            placeholder="Ex: Nº da encomenda"
            v-model="description"
          />
        </div>
      </div>
      <!-- CONDIÇÃO PARA VERIFICAR SE O PAÍS DE ORIGEM É COMUNITÁRIO -->
      <span v-if="this.comunitary == false">
        <!-- <div class="row">
          <div class="col-sm-11"> -->
        <!-- ADICIONAR FATURA -->
        <!-- <label for="file">Fatura</label>
            <input
              @change="uploadImage"
              type="file"
              class="form-control"
              id="file"
            />
          </div>
          <div class="col-sm-1"> -->
        <!-- ADICIONAR FATURA -->
        <!-- <label for="btn">Nº {{ this.order[0].id_invoice }}</label
            ><br />
            <button
              id="btn"
              class="btn btn-primary"
              type="button"
              @click="downloadInvoice()"
            >
              Fatura
            </button>
          </div>
        </div> -->

        <!-- CASO SEJA EXTRA COMUNITÁRIO SURGE FORMULÀRIO PARA COLOCAR-MOS O HS CODE  -->
        <div id="form">
          <!-- v-bind:style="{ display: form }" -->
          <hr />
          <div class="row">
            <div class="col">
              <h3 id="titleForm">Dados Alfandegários</h3>
            </div>
            <div class="col" style="text-align: end">
              <b-button class="canceled" @click="updateProducts">🔁</b-button>
            </div>
          </div>
          <div v-for="article in articles" :key="article.id">
            <div class="form-group row">
              <div class="col-sm-8">
                <label for="product">Produto:</label>
                <input
                  id="product"
                  type="text"
                  class="form-control"
                  v-model="article.name"
                  disabled
                />
              </div>
              <div class="col-sm-2">
                <label for="hscode">HS Code:</label>
                <!-- CASO HAJA MODIFICAÇÃO NO INPUT AUTOMATICAMENTE FAZ O UPDATE -->
                <input
                  id="hscode"
                  type="text"
                  class="form-control"
                  v-model="article.hscode"
                  v-on:change="
                    updateHScode(
                      article.id_backoffice,
                      article.note_warehouse,
                      article.note_finish,
                      article.weight,
                      article.hscode,
                      article.country
                    )
                  "
                  required
                />
              </div>
              <div class="col-sm-2">
                <label for="hscode">Cód. País:</label>
                <!-- CASO HAJA MODIFICAÇÃO NO INPUT AUTOMATICAMENTE FAZ O UPDATE -->
                <input
                  id="hscode"
                  type="text"
                  class="form-control"
                  v-model="article.country"
                  v-on:change="
                    updateHScode(
                      article.id_backoffice,
                      article.note_warehouse,
                      article.note_finish,
                      article.weight,
                      article.hscode,
                      article.country
                    )
                  "
                  required
                />
              </div>
            </div>
          </div>
        </div>
      </span>
      <div class="row">
        <div class="col-sm-10"></div>
        <div class="col-sm-2">
          <button class="btn btn-primary mb-2" type="submit">ENVIAR</button>
        </div>
      </div>
    </form>
    <b-modal ref="my-modal" id="modal-2" title="Editar Morada" hide-footer>
      <form v-on:submit.prevent="updateCountry()">
        <div class="form-group">
          <label for="exampleFormControlSelect1">País</label>
          <select
            class="form-control"
            id="exampleFormControlSelect1"
            v-model="selectCountry"
          >
            <option
              v-for="country in countries"
              :key="country.code"
              :value="country.code"
            >
              {{ country.code }} ({{ country.name }})
            </option>
          </select>
        </div>
        <div id="btnUpdateCountry">
          <button type="submit" class="btn btn-primary">Atualizar País</button>
        </div>
      </form>
    </b-modal>
    <!-- Modal de tradução -->
    <b-modal
      id="modal-1"
      title="Tradução de morada"
      ref="modal_translate"
      hide-footer
    >
      <div class="form-group">
        <label for="contactInput">Morada:</label>
        <input
          type="text"
          class="form-control"
          id="contactInput"
          v-model="address_translate"
        />
        <b-form-checkbox
          v-model="accept_address"
          name="check-button"
          switch
          size="lg"
        ></b-form-checkbox>
      </div>
      <div class="form-group">
        <label for="contactInput">Cidade:</label>
        <input
          type="text"
          class="form-control"
          id="contactInput"
          v-model="city_translate"
        />
        <b-form-checkbox
          v-model="accept_city"
          name="check-button"
          switch
          size="lg"
        ></b-form-checkbox>
      </div>
      <div class="form-group">
        <label for="contactInput">Estado:</label>
        <input
          type="text"
          class="form-control"
          id="contactInput"
          v-model="state_translate"
        />
        <b-form-checkbox
          v-model="accept_state"
          name="check-button"
          switch
          size="lg"
        ></b-form-checkbox>
      </div>
      <button
        type="click"
        class="btn btn-primary"
        @click="updateAddressTranslated()"
      >
        Traduzir
      </button>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Loading from '@/components/Loading.vue';
export default {
  components: {
    Loading,
  },
  props: {
    orderId: String,
    articles: Array,
    order: Array,
    comunitary: Boolean,
    amostras: Number,
    weight: Number,
    height1: Number,
    width1: Number,
    length1: Number,
    selectedMaterials1: Array,
  },
  data: function () {
    return {
      weigth: '',
      allProducts: [],
      products: [],
      product: '',
      service: '',
      link: '',
      env: '',
      fullName: '',
      total: '',
      obs: '',
      finalAdress: this.order[0].adress1 + this.order[0].adress2,
      firstName: this.order[0].first_name,
      secondName: this.order[0].second_name,
      city: this.order[0].city,
      phone: this.order[0].phone,
      expedition: [],
      width: this.width1,
      height: this.height1,
      length: this.length1,
      dhlExp: [],
      allProd: [],
      description: this.order[0].id,
      filePdf: 'null',
      loading: false,
      form: 'none',
      xmlCode: '',
      erro: false,
      pautalCode: [],
      weigthInput: this.weight,
      postalCode: this.order[0].postcode,
      tracking: '',
      linesItems: [],
      invoiceN: '',
      invoiceDate: '',
      code: '',
      cod_docs_dhl: '',
      country: this.order[0].country,
      state: this.order[0].state,
      selectCountry: '',
      countries: [],
      //Translate
      address_translate: '',
      city_translate: '',
      state_translate: '',
      accept_address: true,
      accept_city: true,
      accept_state: true,
      //Product Material
      productTranMaterial: [],
      selectedMaterials: this.selectedMaterials1,
      finalMessage: '',
      weight_justification: '',
    };
  },
  methods: {
    /* CRIAR ENVIO DHL COMUNITÁRIO */
    async dhl() {
      this.loading = true;
      try {
        let volumetric = (this.length * this.width * this.height) / 5000;
        console.log(this.postalCode);
        await this.$store.dispatch('shipmentDHL', {
          phone: this.phone,
          companyName: `${this.firstName} ${this.secondName}`,
          fullName: `${this.firstName} ${this.secondName}`,
          cityName: this.city,
          countryCode: this.country,
          postalCode: this.postalCode,
          addressLine1: this.finalAdress,
          addressLine2: this.finalAdress,
          countryName: this.country,
          weight: this.weigthInput,
          length: this.length,
          width: this.width,
          height: this.height,
          order: this.orderId,
          description: this.description,
          email: this.order[0].email,
          volumetric: volumetric,
          shippingMaterial: this.selectedMaterials,
          weight_justification: this.weight_justification,
        });
        this.dhlExp = this.getDhlExpedition.shipmentTrackingNumber;
        this.tracking = this.getDhlExpedition.packages[0].trackingNumber;
        console.log(this.dhlExp);
        await this.$parent.getCallDHLLabel(this.dhlExp, this.tracking, true);
        await this.$parent.getOrder();
      } catch (err) {
        console.log(err);
        this.$swal({
          icon: 'error',
          title: 'Ooops',
          text: err,
        });
      } finally {
        /* TIMER PARA AGUARDAR-MOS QUE OS DADOS SEJAM INSERIDOS NA DHL PARA O CONSEGUIRMOS OBTER  */
        /*  setTimeout(this.xml(), 10000); */
      }
    },
    /* CRIAR ENVIO DHL EXTRA COMUNITÁRIO */
    async dhlExtra() {
      console.log(this.postalCode);
      console.log(this.weigth);
      this.loading = true;
      this.error = false;
      this.linesItems = [];
      // await this.productsOrder();
      await this.getPautalCode();
      this.pautalCode;
      /* let counter; */
      /*       console.log(this.articles);
      console.log(this.products); */
      /*  for (let i = 0; i < this.products.length; i++) { */
      // CASO TENHA MAIS DE 5 ARTIGOS DEVIDIMOS POR HSCODE
      if (this.articles.length >= 5) {
        console.log('MAIS DE 5 PRODUTOS');
        for (let j = 0; j < this.articles.length; j++) {
          let product = this.articles[j];
          console.log('PRODUTO DATA: ', this.articles[j]);
          // Obter código com o nome da descrição do HSCode
          let code = await this.pautalCode.find(
            (code) => code.hs_code == product.hscode
          );
          // Caso não tenhamos armazenado o hscode quebramos e surge erro
          if (code == undefined) {
            this.$swal({
              icon: 'error',
              title: 'Ooops',
              text: `HSCODE ${product.hscode} não existe armazenado!`,
            });
            this.loading = false;
            this.linesItems = [];
            return -1;
          }
          console.log('Código: ', code);
          console.log('ENTROU PARA LINE OBJECTS');
          let coin = this.order[0].coin;
          let price =
            Number(this.articles[j].price) * Number(this.articles[j].quantity);
          if (this.order[0].email == 'shebin.panikkaveetil@gmail.com') {
            coin = 'EUR';
            price = (
              Number(this.articles[j].total) / Number(this.amostras)
            ).toFixed(2);
          }
          // Verificar se o hscode com o mesmo país de fabrico está inserido
          let t = this.linesItems.findIndex(
            (prod) => prod.commodityCodes[0].value == product.hscode
            // &&
            // prod.manufacturerCountry == product.country
          );
          console.log('T: ', t);
          // Se não encontrar hscode inderido inserimos
          if (t == -1) {
            let object = {
              number: this.linesItems.length + 1,
              commodityCodes: [
                {
                  value: product.hscode,
                  typeCode: 'outbound',
                },
                {
                  value: product.hscode,
                  typeCode: 'inbound',
                },
              ],
              priceCurrency: coin,
              quantity: {
                unitOfMeasurement: 'BOX',
                value: Number(this.articles[j].quantity),
              },
              price: Number(price),
              description: code.description,
              weight: {
                netValue: Number(
                  parseFloat(product.weight / 1000).toFixed(3) *
                    Number(this.articles[j].quantity)
                ),
                grossValue: Number(
                  parseFloat(product.weight / 1000).toFixed(3)
                ),
              },
              exportReasonType: 'permanent',
              manufacturerCountry: product.country,
            };
            this.linesItems.push(object);
            console.log('LineItems: ', this.linesItems);
            console.log(object);
          } else {
            this.linesItems[t].price = +(
              Number(this.linesItems[t].price) + Number(price)
            ).toFixed(2);
            console.log(
              'VALUE: ',
              this.linesItems[t].quantity.value,
              'VALUE 2: ',
              this.articles[j].quantity
            );
            this.linesItems[t].quantity.value =
              Number(this.linesItems[t].quantity.value) +
              Number(this.articles[j].quantity);
            this.linesItems[t].weight.netValue = +(
              Number(this.linesItems[t].weight.netValue) +
              Number(
                parseFloat(product.weight / 1000).toFixed(3) *
                  Number(this.articles[j].quantity)
              )
            ).toFixed(3);
            this.linesItems[t].weight.grossValue = +(
              Number(this.linesItems[t].weight.grossValue) +
              Number(
                parseFloat(product.weight / 1000).toFixed(3) *
                  Number(this.articles[j].quantity)
              )
            ).toFixed(3);
            console.log('LineItems Igual: ', this.linesItems[0]);
          }
        }
        // CALCULAR PREÇO MÉDIO E PESO MÉDIO
        for (const line of this.linesItems) {
          console.log(line);
          line.price = +(line.price / line.quantity.value).toFixed(2);
          line.weight.netValue = +(
            line.weight.netValue / line.quantity.value
          ).toFixed(3);
          line.weight.grossValue = +(
            line.weight.grossValue / line.quantity.value
          ).toFixed(3);
        }
        // await this.linesItems.forEach((line) => {
        //   line.commodityCodes = [];
        // });
        console.log('NOVAS LINES: ', this.linesItems);
      } else {
        console.log('MENOS DE 5 PRODUTOS');
        for (let j = 0; j < this.articles.length; j++) {
          let product = this.articles[j];
          let coin = this.order[0].coin;
          let price = Number(this.articles[j].price);
          if (this.order[0].email == 'shebin.panikkaveetil@gmail.com') {
            coin = 'EUR';
            price = (
              Number(this.articles[j].price) / Number(this.amostras)
            ).toFixed(2);
          }
          let object = {
            number: j + 1,
            commodityCodes: [
              // {
              //   value: product.hscode,
              //   typeCode: 'outbound',
              // },
              // {
              //   value: product.hscode,
              //   typeCode: 'inbound',
              // },
            ],
            priceCurrency: coin,
            quantity: {
              unitOfMeasurement: 'BOX',
              value: Number(this.articles[j].quantity),
            },
            price: Number(price),
            description: this.articles[j].name,
            weight: {
              netValue: Number(parseFloat(product.weight / 1000).toFixed(3)),
              grossValue: Number(parseFloat(product.weight / 1000).toFixed(3)),
            },
            exportReasonType: 'permanent',
            manufacturerCountry: product.country,
          };
          this.linesItems.push(object);
          console.log('LineItems: ', this.linesItems);
        }
      }

      console.log('LINE ITEMS Final: ', this.linesItems);
      try {
        let volumetric = (this.length * this.width * this.height) / 5000;
        await this.$store.dispatch('shipmentDHLExtraComunitary', {
          phone: this.phone,
          companyName: `${this.firstName} ${this.secondName}`,
          fullName: `${this.firstName} ${this.secondName}`,
          cityName: this.city,
          countryCode: this.country,
          postalCode: this.postalCode,
          addressLine1: this.finalAdress,
          addressLine2: this.finalAdress,
          countryName: this.country,
          weight: this.weigthInput,
          length: this.length,
          width: this.width,
          height: this.height,
          order: this.orderId,
          description: this.description,
          value: this.order[0].total,
          currency: this.order[0].coin,
          pdf: this.filePdf,
          lineItems: this.linesItems,
          invoice: this.order[0].id_invoice,
          email: this.order[0].email,
          shipping: this.order[0].shipping_lines,
          volumetric: volumetric,
          shippingMaterial: this.selectedMaterials,
          weight_justification: this.weight_justification,
        });
        this.dhlExp = this.getDhlExpedition.shipmentTrackingNumber;
        this.tracking = this.getDhlExpedition.packages[0].trackingNumber;
        console.log(this.dhlExp);
        console.log(this.linesItems);
        await this.$parent.getOrder();
        /* await this.getPautalCode(); */
      } catch (err) {
        /* Tornamos o erro verdadeiro */
        console.log(err);
        this.error = true;
        this.$swal({
          icon: 'error',
          title: 'Ooops',
          text: err,
        });
      } finally {
        /* Caso o erro seja verdadeiro não avançamos para a criação do xml */
        if (this.error == true) {
          this.loading = false;
          /* this.$parent.getCallDHLLabel(this.dhlExp, this.tracking, false); */
        } else {
          /*  setTimeout(() => {
            this.xml(this.dhlExp);
          }, 4000); */
          this.$parent.getCallDHLLabel(this.dhlExp, this.tracking, true);
        }
      }
    },
    /* SUBMETER FORMULÁRIO */
    async submit() {
      // Inicializa a variável para controlar se deve prosseguir
      let canProceed = true;

      // Calcular a diferença percentual
      const expectedWeight = Number(this.order[0].expected_weight); // Peso esperado
      const weightInput = Number(this.weigthInput); // Peso introduzido

      // Verifica se o peso está dentro do limite de 30%
      const lowerLimit = expectedWeight * 0.7; // 30% inferior
      const upperLimit = expectedWeight * 1.3; // 30% superior

      if (weightInput < lowerLimit || weightInput > upperLimit) {
        // Solicita justificação ao usuário
        await this.$swal({
          title: 'Justificação para a diferença de peso',
          html: `
        <p>O peso introduzido é ${weightInput} Kg.</p>
        <p>O peso esperado é ${expectedWeight} Kg.</p>
        <input type="text" id="weight_justification" class="form-control" placeholder="Justificação">
      `,
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar',
          showCancelButton: true,
          preConfirm: () => {
            const justification = this.$swal
              .getPopup()
              .querySelector('#weight_justification').value;
            if (!justification) {
              this.$swal.showValidationMessage(
                'Por favor, forneça uma justificação'
              );
            }
            return justification;
          },
        }).then((result2) => {
          if (result2.isConfirmed) {
            this.weight_justification = result2.value; // Armazenar a justificação
          } else {
            canProceed = false;
          }
        });
      }

      // Prossegue normalmente após a justificação (ou se dentro dos limites)
      if (canProceed) {
        if (this.comunitary) {
          this.dhl();
        } else {
          this.dhlExtra();
        }
      }
    },
    /* CRIAR FATURA */
    async createInvoice(order) {
      this.loading = true;
      console.log('Criar Fatura');
      try {
        await this.$store.dispatch('fetchInvoices', {
          order: order,
        });
        setTimeout(this.getAllOrders, 5000);
      } catch (err) {
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'error',
          title: err,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    /* ADICIONAR FATURA */
    /*     uploadImage() {
      var file = document.querySelector("input[type=file]").files[0];
      console.log(file);
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let index = String(reader.result).indexOf(",");
        let base64 = String(reader.result).substring(index + 1);
        console.log(base64.length % 4);
        this.filePdf = base64;
      };
      reader.onerror = function (error) {
        alert(error);
      };
    }, */
    /* OBTER TODOS OS PRODUTOS */
    async getAllProducts() {
      try {
        await this.$store.dispatch('fetchProducts');
        this.allProducts = this.getProducts.data;
        console.log(this.allProducts);
        // this.getPeso();
        // this.productsOrder();
      } catch (err) {
        this.$swal({
          icon: 'error',
          title: 'Ooops',
          text: err,
        });
      }
    },
    /* CALCULAR PESO DOS PRODUTOS */
    async getPeso() {
      this.weigth = 0;
      console.log('Peso');
      for (let i = 0; i < this.articles.length; i++) {
        /* PROCURAR PRODUTO NO ARRAY DE PRODUTOS */
        this.product = this.allProducts.find((product) =>
          product.sku.includes(this.articles[i].sku)
        );
        /* console.log(this.product); */
        /* ADICIONAR AO PESO O PESO TOTAL DE CADA PRODUTO */
        this.weigth =
          this.weigth +
          this.product.weight * parseInt(this.articles[i].quantity);
      }
      /* ADICIONAMOS 10% DO PESO PARA COMPENSAR O PESO DA CAIXA */
      this.weigth = this.weigth + this.weigth * 0.1;
      this.weigth = parseFloat(this.weigth / 6000).toFixed(3);
      this.getWeigth();
    },

    /* ATUALIZAR HSCODE DO PRODUTO */
    async updateHScode(id, warehouse, finish, weight, code, country) {
      console.log(id, warehouse, finish, weight, code, country);
      try {
        await this.$store.dispatch('putProduct', {
          id: id,
          warehouse: warehouse,
          finish: finish,
          weight: parseInt(weight),
          hscode: parseInt(code),
          country: country,
        });
        this.getAllProducts();
      } catch (err) {
        this.$swal({
          icon: 'error',
          title: 'Ooops',
          text: err,
        });
      }
    },

    async getWeigth() {
      for (let i = 0; i < this.articles.length; i++) {
        let product = this.allProducts.find((product) =>
          product.sku.includes(this.articles[i].sku)
        );
        this.allProd.push(product);
      }
    },
    /* OBTER OS DADOS DOS PRODUTOS DA ENCOMENDA */
    async productsOrder() {
      console.log('ENTROU');
      this.products = [];
      console.log(this.articles);
      for (let index = 0; index < this.articles.length; index++) {
        /* console.log(this.articles.length - 1, index); */
        let product = this.allProducts.find((product) =>
          product.sku.includes(this.articles[index].sku)
        );
        /* if (
          (product.hscode == null || product.country == null) &&
          this.comunitary == false
        ) {
          this.form = "block";
        } */
        this.form = 'block';
        this.products.push(product);
      }
      console.log(this.products);
    },

    /* DOWNLOAD FICHEIRO FATURA */
    /*     async downloadInvoice() {
      console.log(this.order[0]);
      if (this.order[0].id_invoice == "") {
        try {
          await this.createInvoice();
          await this.$store.dispatch("fetchPDFFile", {
            id: this.order[0].id_invoice,
          });
        } catch (err) {
          this.$swal({
            toast: true,
            position: "top-end",
            icon: "error",
            title: err,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } else {
        console.log("Fatura já criada");
        try {
          await this.$store.dispatch("fetchPDFFile", {
            id: this.order[0].id_invoice,
          });
        } catch (err) {
          this.$swal({
            toast: true,
            position: "top-end",
            icon: "error",
            title: err,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    }, */
    async invoiceNumber() {
      try {
        await this.$store.dispatch('fetchInvoiceData', {
          id: this.order[0].id_invoice,
        });
        this.invoiceN = this.getInvoiceNumber;
        this.invoiceDate = this.getInvoiceDate;
      } catch (err) {
        alert(err);
      }
    },
    /* OBTER OS CÒDIGOS PAUTAIS */
    async getPautalCode() {
      try {
        await this.$store.dispatch('fetchDHLCodes');
        this.pautalCode = this.getcodesDHL.data;
      } catch (err) {
        alert(err);
      }
    },
    /* CRIAR XML PARA ALFANDEGA DA DHL */
    async xml(expe) {
      await this.invoiceNumber();
      let dateNow = new Date();
      dateNow.setSeconds(dateNow.getSeconds() + 60);
      let year = dateNow.getFullYear();
      let month = ('0' + (dateNow.getMonth() + 1)).slice(-2);
      let day = ('0' + dateNow.getDate()).slice(-2);
      await this.getPautalCode();
      /* TODAS AS CONDIÇÔES ESTÃO RELACIONADAS COM O FICHEIRO DA DHL */
      console.log('XML');
      /* PARA OBTER CÓDIGO DO AEROPORTO */
      try {
        this.loading = false;
        console.log(expe);
        await this.$store.dispatch('fetchTrackingDHL', {
          expedition: expe,
        });
        console.log(this.getDhlTracking.shipments);
      } catch (err) {
        this.$swal({
          icon: 'error',
          title: 'Ooops',
          text: err,
        });
        this.loading = false;
      }
      console.log(this.getDhlTracking);
      /* CÓDIGO DO AEROPORTO DE DESTINO */
      let codeDest =
        this.getDhlTracking.shipments[0].receiverDetails.serviceArea[0].code;
      console.log(codeDest);
      let parser = new DOMParser();
      await this.$store.dispatch('labelDHL', {
        expedition: expe,
      });
      console.log(this.order);
      console.log(this.order[0].shipping_lines);
      let shippingLines = JSON.parse(this.order[0].shipping_lines);
      console.log(shippingLines[0].total);
      let date = this.getDhlLabel.data[0];
      console.log(date);
      this.xmlCode = `<DeclaracoesExpToGIDWin xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
    <Declaracao Acao="I">
        <TipoProcedimento>A</TipoProcedimento>
        <CodNatureza>XML</CodNatureza>
        <CodOperador>00000</CodOperador>
        <Data>${this.getDhlLabel.data[0].date}Z</Data>
        <VossaReferencia>${expe}</VossaReferencia>
        <CodEstanciaA>PT000020</CodEstanciaA>`;
      /* CONDIÇÃO PARA O PAÍS DE EXPORTAÇÃO */
      if (this.order[0].country == 'ES' || this.order[0].country == 'FR') {
        this.xmlCode =
          this.xmlCode + '<CodTipoDeclaracao>COM1</CodTipoDeclaracao>';
      } else if (
        this.order[0].country == 'GB' ||
        this.order[0].country == 'CH' ||
        this.order[0].country == 'IS' ||
        this.order[0].country == 'NO' ||
        this.order[0].country == 'TR' ||
        this.order[0].country == 'XS' ||
        this.order[0].country == 'IS' ||
        this.order[0].country == 'LI' ||
        this.order[0].country == 'MK'
      ) {
        this.xmlCode =
          this.xmlCode + '<CodTipoDeclaracao>EU A</CodTipoDeclaracao>';
      } else {
        this.xmlCode =
          this.xmlCode + '<CodTipoDeclaracao>EX A</CodTipoDeclaracao>';
      }
      this.xmlCode =
        this.xmlCode +
        `<NumeroCPA>${expe}</NumeroCPA>
        <TipoCarga>X</TipoCarga>
        <DataVoo>${year}-${month}-${day}Z</DataVoo>
        <Expedidor>
            <Codigo>516494430</Codigo>
            <Nome>SEARCHMATTERS LDA</Nome>
            <Morada>Rua 5 de Outubro, N.º 490</Morada>
            <Localidade>Braga</Localidade>
            <CodPostal>4700-260</CodPostal>
            <LocPostal>Braga</LocPostal>
            <NIF>516494430</NIF>
            <CodPais>PT</CodPais>
        </Expedidor>
        <NumeroComercialRef>${expe}</NumeroComercialRef>
        <Destinatario>
            <Nome>${this.order[0].first_name} ${this.order[0].second_name}</Nome>
            <Morada>${this.order[0].adress1}</Morada>
            <Localidade>${this.order[0].city}</Localidade>
            <CodPostal>${this.order[0].postcode}</CodPostal>
            <LocPostal>${this.order[0].city}</LocPostal>
            <CodPais>${this.order[0].country}</CodPais>
        </Destinatario>
        <CodPais15A>PT</CodPais15A>
        <CodPais17A>${this.order[0].country}</CodPais17A>
        <ICE>A</ICE>
        <MeioTransportePartida>
            <CodMeioTransporte25>40</CodMeioTransporte25>
            <CodAeroporto>${codeDest}</CodAeroporto>
        </MeioTransportePartida>
        <MeioTransporteFronteira>
            <CodMeioTransporte>40</CodMeioTransporte>
            <MatriculaMT>QY8213 ${this.getDhlLabel.data[0].date}</MatriculaMT>
            <CodPais>BE</CodPais>
        </MeioTransporteFronteira>
        <CodEstanciaTAC>PT000020</CodEstanciaTAC>
        <LocalizacaoMercadorias>
            <CodLocalAutorizado>AEX00000473020PT</CodLocalAutorizado>
        </LocalizacaoMercadorias>
        <CodCondEntrega>CPT</CodCondEntrega>
        <Lugar>${this.order[0].city}</Lugar>
        <CodNaturezaTransacao>11</CodNaturezaTransacao>
        <CodPais>${this.order[0].country}</CodPais>
        <TotalFrete>${shippingLines[0].total}</TotalFrete>
        <TotalSeguro>0.000000</TotalSeguro>
        <Origem>1</Origem>
        <Adicoes>`;
      console.log(this.xmlCode);
      /* INSERIR OS PRODUTOS AO FICHEIRO XML */
      for (let index = 0; index < this.articles.length; index++) {
        console.log(this.articles.length - 1, index);
        let product = this.allProducts.find((product) =>
          product.sku.includes(this.articles[index].sku)
        );
        let codes = [];
        console.log(product.hscode);
        console.log(this.pautalCode);
        codes = await this.pautalCode.find(
          (code) => code.hs_code == product.hscode
        );
        console.log(codes);
        if (codes == undefined) {
          this.code = 33049900;
          this.cod_docs_dhl = 'Y920';
        } else {
          this.code = codes.cod;
          this.cod_docs_dhl = codes.cod_docs_dhl;
        }

        let weigth = product.weight * parseInt(this.articles[index].quantity);
        weigth = parseFloat((weigth / 1000) * 1.1).toFixed(6);
        let weigthLiquid = parseFloat(weigth * 0.95).toFixed(6);
        /* ADICIONAR DADOS DE CADA PRODUTO */
        this.xmlCode =
          this.xmlCode +
          `<Adicao>
                <NumeroAdicao>${index + 1}</NumeroAdicao>
                <CodArtigo>${this.code}</CodArtigo>
                <DesignacaoArtigo>${
                  this.articles[index].name
                }</DesignacaoArtigo>
                <CodPaisOrigem>PT</CodPaisOrigem>
                <MassaBruta>${weigth}</MassaBruta>
                <MassaLiquida>${weigthLiquid}</MassaLiquida>
                <Regime>1000</Regime>
                <QtdUnidades>0</QtdUnidades>
                <ValorAdicao>${this.articles[index].subtotal}</ValorAdicao>
                <ValorFrete>0.000000</ValorFrete>
                <ValorSeguro>0.000000</ValorSeguro>
                `;
        if (index == 0) {
          /* NO PRIMEIRO PRODUTO TEMOS QUE COLOCAR A INFORMAÇÃO A BAIXO */
          this.xmlCode =
            this.xmlCode +
            `<Documentos>       
                    <Documento>
                        <CodDocumento>N830</CodDocumento>
                        <NumeroReferencia>FR ${this.invoiceN}</NumeroReferencia>
                        <DataEmissao>${this.invoiceDate}</DataEmissao>
                    </Documento>
                    <Documento>
                        <CodDocumento>N740</CodDocumento>
                        <NumeroReferencia>${expe}</NumeroReferencia>
                        <DataEmissao>${this.getDhlLabel.data[0].date}</DataEmissao>
                    </Documento>
                    <Documento>
                        <CodDocumento>N830</CodDocumento>
                        <NumeroReferencia>${expe}</NumeroReferencia>
                        <DataEmissao>${this.getDhlLabel.data[0].date}</DataEmissao>
                    </Documento>
            </Documentos>
            <MencoesEspeciais>
                    <MencaoEspecial>
                        <CodMencao>DHAB</CodMencao>
                    </MencaoEspecial>
                    <MencaoEspecial>
                        <CodMencao>CTU</CodMencao>
                    </MencaoEspecial>
                    <MencaoEspecial>
                        <CodMencao>DCCS</CodMencao>
                    </MencaoEspecial>
                </MencoesEspeciais>`;
        }
        console.log(this.articles.length - 1, index);
        /* DEPENDENDO DA QUANTIDADE DE PRODUTOS A INFORMAÇÃO 'P' E 'R' ALTERAM */
        if (this.articles.length == 1) {
          /* CASO SEJA SÓ UM PRODUTO 'P' E 'R' ASSUME FALSE */
          this.xmlCode =
            this.xmlCode +
            `<MarcasEVolumes>
                    <MarcaEVolume>
                        <Marca>LET</Marca>
                        <CodNaturezaVols>CT</CodNaturezaVols>
                        <NumeroVols>1.000000</NumeroVols>
                        <P>false</P>
                        <R>false</R>
                    </MarcaEVolume>
                </MarcasEVolumes>
                   </Adicao>
                </Adicoes>
          </Declaracao>
      </DeclaracoesExpToGIDWin>`;
        } else if (this.articles.length - 1 != index) {
          console.log('Não é o último produto');
          /* CASO SEJA SÓ MAIS QUE UM PRODUTO 'P' ASSUME TRUE E 'R' ASSUME FALSE */
          this.xmlCode =
            this.xmlCode +
            `<MarcasEVolumes>
                    <MarcaEVolume>
                        <Marca>LET</Marca>
                        <CodNaturezaVols>CT</CodNaturezaVols>
                        <NumeroVols>1.000000</NumeroVols>
                        <P>true</P>
                        <R>false</R>
                    </MarcaEVolume>
                </MarcasEVolumes>
                </Adicao>
             `;
        } else if (this.articles.length - 1 == index) {
          /* CASO SEJA SÓ MAIS QUE UM PRODUTO NO ÚLTIMO O 'P' ASSUME FALSE E 'R' ASSUME TRUE */
          console.log('final');
          this.xmlCode =
            this.xmlCode +
            `<MarcasEVolumes>
                    <MarcaEVolume>
                        <Marca>LET</Marca>
                        <CodNaturezaVols>CT</CodNaturezaVols>
                        <NumeroVols>1.000000</NumeroVols>
                        <P>false</P>
                        <R>true</R>
                    </MarcaEVolume>
                </MarcasEVolumes>
                </Adicao>
            </Adicoes>
            </Declaracao>
          </DeclaracoesExpToGIDWin>`;
        }
      }
      console.log(this.xmlCode);
      let xml = parser.parseFromString(this.xmlCode, 'text/xml');
      console.log(xml);
      /* CRIAÇÃO DE UM FICHEIRO XML */
      var filename = `${this.orderId}.xml`;
      var pom = document.createElement('a');
      var bb = new Blob([this.xmlCode], { type: 'text/plain' });
      pom.setAttribute('href', window.URL.createObjectURL(bb));
      pom.setAttribute('download', filename);
      pom.dataset.downloadurl = ['text/plain', pom.download, pom.href].join(
        ':'
      );
      pom.draggable = true;
      pom.classList.add('dragout');
      pom.click();
      /* SWEET-ALERT */
      this.$swal({
        toast: true,
        position: 'top-end',
        icon: 'success',
        title: 'Dados Alfandegários Enviado',
        showConfirmButton: false,
        timer: 1500,
      });
      try {
        this.$store.dispatch('sendXML', {
          xml: this.xmlCode,
          orderId: this.order[0].id,
          exp: expe,
        });
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'success',
          title: 'Dados Alfandegários Enviado',
          showConfirmButton: false,
          timer: 1500,
        });
      } catch (error) {
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'error',
          title: error,
          showConfirmButton: false,
          timer: 1500,
        });
      } finally {
        this.loading = false;
      }

      /* this.$store.dispatch("putXML", {
        id: this.order[0].id,
      }); */
    },
    async updateCountry() {
      this.country = this.selectCountry;
      this.$refs['my-modal'].hide();
    },
    async allCountries() {
      this.selectCountry = this.country;
      console.log(this.selectCountry);
      try {
        await this.$store.dispatch('fetchAllCountries');
        this.countries = this.getAllCountries;
        console.log(this.getAllCountries);
        this.$refs['my-modal'].show();
      } catch (err) {
        alert(err);
      }
    },
    async updateProducts() {
      try {
        await this.$store.dispatch('updateProducts');
      } catch (err) {
        alert(err);
      }
    },
    //Translate
    async translate(address, city, state) {
      try {
        await this.$store.dispatch('translateOrderInfo', {
          address: address,
          city: city,
          state: state,
        });
        let translated = this.getTranslated;
        this.address_translate = translated.address;
        this.city_translate = translated.city;
        this.state_translate = translated.state;
        this.$refs['modal_translate'].show();
      } catch (err) {
        console.log(err);
      }
    },
    async updateAddressTranslated() {
      if (this.accept_address == true) {
        this.finalAdress = this.address_translate;
      }

      if (this.accept_city == true) {
        this.city = this.city_translate;
      }

      if (this.accept_state == true) {
        this.state = this.state_translate;
      }

      this.$refs['modal_translate'].hide();
      this.address_translate = '';
      this.city_translate = '';
      this.state_translate = '';
      this.accept_address = true;
      this.accept_city = true;
      this.accept_state = true;
    },
    //Product Material
    async getProductTranMaterialF() {
      try {
        await this.$store.dispatch('getProductsTranMaterial', {
          id: this.order[0].id,
        });
        this.productTranMaterial = this.getProductTranMaterial.mat;
        this.finalMessage = this.getProductTranMaterial.message;
        console.log(this.productTranMaterial);
      } catch (err) {
        this.$swal({
          icon: 'error',
          title: 'Ooops',
          text: err,
        });
      }
    },
    toggleMaterial(sku) {
      const material = this.productTranMaterial.find((mat) => mat.sku === sku);

      // Limpa todas as seleções anteriores, seja caixa com dimensões ou produtos sem dimensões
      this.selectedMaterials = [];

      if (material && material.dimensions) {
        // Se a caixa já estiver selecionada, desmarca-a e limpa as dimensões
        if (this.selectedMaterials.includes(sku)) {
          // Limpa as dimensões ao desmarcar a caixa
          this.length = null;
          this.width = null;
          this.height = null;
        } else {
          // Adiciona a nova caixa e atribui suas dimensões
          this.selectedMaterials.push(sku);
          this.length = material.dimensions.length;
          this.width = material.dimensions.width;
          this.height = material.dimensions.height;
        }
      } else {
        // Para produtos sem dimensões (envelopes, sacos, etc.), adiciona à lista
        this.selectedMaterials.push(sku);

        // Limpa as dimensões, pois não é uma caixa
        this.length = null;
        this.width = null;
        this.height = null;
      }
    },
  },
  computed: {
    /* GETTERS para retornar valores da store */
    ...mapGetters(['getProducts']),
    ...mapGetters(['getCurrentTotal']),
    ...mapGetters(['getDhlExpedition']),
    ...mapGetters(['getDhlTracking']),
    ...mapGetters(['getDhlLabel']),
    ...mapGetters(['getcodesDHL']),
    ...mapGetters(['getInvoiceNumber']),
    ...mapGetters(['getInvoiceDate']),
    ...mapGetters(['getAllCountries']),
    ...mapGetters(['getTranslated']),
    ...mapGetters(['getProductTranMaterial']),
  },
  mounted() {
    this.$root.$on('createXML', (expedit) => {
      // your code goes here
      this.xml(expedit);
    });
  },
  created() {
    this.getProductTranMaterialF();

    // this.getAllProducts();
    // this.getAllOrders();
  },
};
</script>

<style scoped>
.card {
  padding: 25px;
  background-color: rgba(255, 147, 147, 0.733);
  margin: 50px;
  border: 1px solid #dddd;
  border-radius: 18px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.row {
  margin-bottom: 15px;
}
hr {
  background-color: white;
  height: 2px;
  border: 0;
}
h3 {
  color: white;
}
#send {
  text-align: right;
}
#btnUpdateCountry {
  text-align: center;
}
.canceled {
  background-color: red;
  border-color: rgb(255, 0, 0);
}

#aUpdateAddress {
  margin-left: 5px;
  cursor: pointer;
}
#aUpdateAddress:hover {
  cursor: pointer;
}

.material-container {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.material-box {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  background-color: #f9f9f9;
  text-align: center;
  width: 150px; /* largura fixa para as caixas */
}

.material-box:hover {
  background-color: #e0e0e0;
}

.material-box.selected {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

@media (max-width: 600px) {
  .material-box {
    width: 100%; /* Em telas menores, as caixas ocuparão toda a largura */
  }
}
</style>
