<template>
  <div class="card">
    <!-- LOADING COMPONENTE -->
    <span id="loading" v-if="this.loading == true">
      <Loading />
    </span>
    <h3>CTT</h3>
    <hr />
    <!-- FORMULÁRIO DO ENVIO DA DHL -->
    <form v-on:submit.prevent="submit()">
      <div class="row">
        <div class="col">
          <!-- NOME DO DESTINATÁRIO -- NÃO EDITÁVEL -->
          <label for="firstName">Primerio Nome:</label>
          <input
            id="firstName"
            type="text"
            class="form-control"
            v-model="firstName"
          />
        </div>
        <div class="col">
          <!-- APELIDO DO DESTINATÁRIO -- NÃO EDITÁVEL -->
          <label for="secondName">Apelido:</label>
          <input
            id="secondname"
            type="text"
            class="form-control"
            v-model="secondName"
          />
        </div>
        <div class="col">
          <!-- EMAIL DO DESTINATÁRIO -- NÃO EDITÁVEL -->
          <label for="email">Email:</label>
          <input
            id="email"
            type="text"
            class="form-control"
            :placeholder="this.order[0].email"
            disabled
          />
        </div>
      </div>
      <div class="row">
        <hr />
        <a id="aUpdateAddress" @click="translate(finalAdress, city, state)"
          >🌐 Traduzir 🌐</a
        >
        <div class="col">
          <!-- MORADA DO DESTINATÁRIO -- NÃO EDITÁVEL -->
          <label for="adress">Morada:</label>
          <input
            id="adress"
            type="text"
            class="form-control"
            v-model="finalAdress"
          />
          <span v-if="this.finalAdress.length > 100">
            <small class="text-danger"
              >ALERTA: {{ this.finalAdress.length }} caracteres de 100</small
            >
          </span>
        </div>
        <div class="col">
          <!-- CIDADE DO DESTINATÁRIO -- NÃO EDITÁVEL -->
          <label for="city">Cidade:</label>
          <input id="city" type="text" class="form-control" v-model="city" />
        </div>
        <div class="col">
          <!-- CIDADE DO DESTINATÁRIO -- NÃO EDITÁVEL -->
          <label for="city">Estado:</label>
          <input id="city" type="text" class="form-control" v-model="state" />
        </div>
        <div class="col">
          <!-- PAÍS DO DESTINATÁRIO -- NÃO EDITÁVEL -->
          <label for="country">País:</label>
          <input
            id="country"
            type="text"
            class="form-control"
            :placeholder="country"
            disabled
          />
          <a href="/" id="aUpdateAddress" @click="allCountries()"
            >✒️ Editar País</a
          >
        </div>
      </div>
      <div class="row">
        <div class="col">
          <!-- TELEFONE DO DESTINATÁRIO -- EDITÁVEL -->
          <label for="postalCode">Código Postal:</label>
          <div class="input-group mb-2">
            <input
              id="postalCode"
              type="text"
              class="form-control"
              v-model="postalCode"
              required
            />
          </div>
        </div>
        <div class="col">
          <!-- MOEDA DO DESTINATÁRIO -- NÃO EDITÁVEL -->
          <label for="total">Total ({{ this.order[0].coin }}):</label>
          <input
            id="total"
            type="text"
            class="form-control"
            :placeholder="this.order[0].total"
            disabled
          />
        </div>
        <div class="col">
          <!-- TELEFONE DO DESTINATÁRIO -- NÃO EDITÁVEL -->
          <label for="phone">Contacto Telefónico:</label>
          <div class="input-group mb-2">
            <input
              id="phone"
              type="text"
              class="form-control"
              v-model="phone"
            />
          </div>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col">
          <!-- MATERIAIS DE ENVIO (caixas interativas) -->
          <label>Materiais de Envio:</label>
          <div class="material-container">
            <div
              v-for="material in productTranMaterial"
              :key="material.sku"
              :class="[
                'material-box',
                { selected: selectedMaterials.includes(material.sku) },
              ]"
              @click="toggleMaterial(material.sku)"
            >
              {{ material.name }}
            </div>
          </div>
        </div>
      </div>
      <!-- Adicionando a mensagem gerada -->
      <div class="row mt-3">
        <div class="col">
          <div v-if="finalMessage != ''" class="alert alert-info">
            <div v-html="finalMessage"></div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <!-- ALTURA DA CAIXA DE ENVIO -- EDITÁVEL -->
          <label for="height">Altura (cm):</label>
          <input
            id="height"
            type="number"
            class="form-control"
            v-model="height"
            required
          />
        </div>
        <div class="col">
          <!-- LARGURA DA CAIXA DE ENVIO -- EDITÁVEL-->
          <label for="length">Largura (cm):</label>
          <input
            id="length"
            type="number"
            class="form-control"
            v-model="length"
            required
          />
        </div>
        <div class="col">
          <!-- COMPRIMENTO DA CAIXA DE ENVIO -- EDITÁVEL -->
          <label for="width">Comprimento (cm):</label>
          <input
            id="width"
            type="number"
            class="form-control"
            v-model="width"
            required
          />
        </div>
        <div class="col">
          <!-- PESO DA ENCOMENDA -- NÃO EDITÁVEL -->
          <label for="weigth">Peso (Kg):</label>
          <input
            id="length"
            type="text"
            class="form-control"
            v-model="weigthInput"
          />
          <a style="color: red">Peso esperado:</a>
          {{ this.order[0].expected_weight }} Kg
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label for=""> Peso Volumétrico:</label>
          <span
            v-if="
              (this.length * this.width * this.height) / 5000 > this.weigthInput
            "
            style="color: red"
          >
            {{ (this.length * this.width * this.height) / 5000 }} KG
          </span>
          <span v-else>
            {{ (this.length * this.width * this.height) / 5000 }} KG
          </span>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <!-- PEQUENA DESCRIÇÃO DO ENVIO_ex:nºencomenda -- EDITÁVEL -->
          <label for="length">Descrição:</label>
          <input
            id="length"
            type="text"
            class="form-control"
            placeholder="Ex: Nº da encomenda"
            v-model="description"
          />
        </div>
      </div>
      <!-- CONDIÇÃO PARA VERIFICAR SE O PAÍS DE ORIGEM É COMUNITÁRIO -->
      <span v-if="this.order[0].country != 'PT'">
        <!-- CASO SEJA EXTRA COMUNITÁRIO SURGE FORMULÀRIO PARA COLOCAR-MOS O HS CODE  -->
        <div id="form">
          <!-- v-bind:style="{ display: form }" -->
          <hr />
          <div class="row">
            <div class="col">
              <h3 id="titleForm">Dados Alfandegários</h3>
            </div>
            <div class="col" style="text-align: end">
              <b-button class="canceled" @click="updateProducts">🔁</b-button>
            </div>
          </div>
          <div v-for="article in articles" :key="article.id">
            <div class="form-group row">
              <div class="col-sm-8">
                <label for="product">Produto:</label>
                <input
                  id="product"
                  type="text"
                  class="form-control"
                  v-model="article.name"
                  disabled
                />
              </div>
              <div class="col-sm-2">
                <label for="hscode">HS Code:</label>
                <!-- CASO HAJA MODIFICAÇÃO NO INPUT AUTOMATICAMENTE FAZ O UPDATE -->
                <input
                  id="hscode"
                  type="text"
                  class="form-control"
                  v-model="article.hscode"
                  v-on:change="
                    updateHScode(
                      article.id_backoffice,
                      article.note_warehouse,
                      article.note_finish,
                      article.weight,
                      article.hscode,
                      article.country
                    )
                  "
                  required
                />
              </div>
              <div class="col-sm-2">
                <label for="hscode">Cód. País:</label>
                <!-- CASO HAJA MODIFICAÇÃO NO INPUT AUTOMATICAMENTE FAZ O UPDATE -->
                <input
                  id="hscode"
                  type="text"
                  class="form-control"
                  v-model="article.country"
                  v-on:change="
                    updateHScode(
                      article.id_backoffice,
                      article.note_warehouse,
                      article.note_finish,
                      article.weight,
                      article.hscode,
                      article.country
                    )
                  "
                  required
                />
              </div>
            </div>
          </div>
        </div>
      </span>
      <div class="row">
        <div class="col-sm-10"></div>
        <div class="col-sm-2">
          <button class="btn btn-primary mb-2" type="submit">ENVIAR</button>
        </div>
      </div>
    </form>
    <b-modal ref="my-modal" id="modal-2" title="Editar Morada" hide-footer>
      <form v-on:submit.prevent="updateCountry()">
        <div class="form-group">
          <label for="exampleFormControlSelect1">País</label>
          <select
            class="form-control"
            id="exampleFormControlSelect1"
            v-model="selectCountry"
          >
            <option
              v-for="country in countries"
              :key="country.code"
              :value="country.code"
            >
              {{ country.code }} ({{ country.name }})
            </option>
          </select>
        </div>
        <div id="btnUpdateCountry">
          <button type="submit" class="btn btn-primary">Atualizar País</button>
        </div>
      </form>
    </b-modal>
    <!-- Modal de tradução -->
    <b-modal
      id="modal-1"
      title="Tradução de morada"
      ref="modal_translate"
      hide-footer
    >
      <div class="form-group">
        <label for="contactInput">Morada:</label>
        <input
          type="text"
          class="form-control"
          id="contactInput"
          v-model="address_translate"
        />
        <b-form-checkbox
          v-model="accept_address"
          name="check-button"
          switch
          size="lg"
        ></b-form-checkbox>
      </div>
      <div class="form-group">
        <label for="contactInput">Cidade:</label>
        <input
          type="text"
          class="form-control"
          id="contactInput"
          v-model="city_translate"
        />
        <b-form-checkbox
          v-model="accept_city"
          name="check-button"
          switch
          size="lg"
        ></b-form-checkbox>
      </div>
      <div class="form-group">
        <label for="contactInput">Estado:</label>
        <input
          type="text"
          class="form-control"
          id="contactInput"
          v-model="state_translate"
        />
        <b-form-checkbox
          v-model="accept_state"
          name="check-button"
          switch
          size="lg"
        ></b-form-checkbox>
      </div>
      <button
        type="click"
        class="btn btn-primary"
        @click="updateAddressTranslated()"
      >
        Traduzir
      </button>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Loading from '@/components/Loading.vue';
export default {
  components: {
    Loading,
  },
  props: {
    orderId: String,
    articles: Array,
    order: Array,
    comunitary: Boolean,
    weight: Number,
    height1: Number,
    width1: Number,
    length1: Number,
    selectedMaterials1: Array,
  },
  data: function () {
    return {
      weigth: '',
      allProducts: [],
      products: [],
      obs: '',
      finalAdress: this.order[0].adress1 + this.order[0].adress2,
      firstName: this.order[0].first_name,
      secondName: this.order[0].second_name,
      phone: this.order[0].phone,
      width: this.width1,
      height: this.height1,
      length: this.length1,
      cttExpressoExp: [],
      allProd: [],
      description: this.order[0].id,
      filePdf: 'null',
      loading: false,
      form: 'none',
      erro: false,
      pautalCode: [],
      weigthInput: this.weight,
      postalCode: this.order[0].postcode,
      tracking: '',
      customsDatas: '',
      city: this.order[0].city,
      country: this.order[0].country,
      state: this.order[0].state,
      selectCountry: '',
      countries: [],
      //Translate
      address_translate: '',
      city_translate: '',
      state_translate: '',
      accept_address: true,
      accept_city: true,
      accept_state: true,
      //Product Material
      productTranMaterial: [],
      selectedMaterials: this.selectedMaterials1,
      finalMessage: '',
      weight_justification: '',
    };
  },
  methods: {
    async updateHScode(id, warehouse, finish, weight, code, country) {
      try {
        await this.$store.dispatch('putProduct', {
          id: id,
          warehouse: warehouse,
          finish: finish,
          weight: parseInt(weight),
          hscode: parseInt(code),
          country: country,
        });
        // this.getAllProducts();
      } catch (err) {
        this.$swal({
          icon: 'error',
          title: 'Ooops',
          text: err,
        });
      }
    },
    async submit() {
      // Inicializa a variável para controlar se deve prosseguir
      let canProceed = true;

      // Calcular a diferença percentual
      const expectedWeight = Number(this.order[0].expected_weight); // Peso esperado
      const weightInput = Number(this.weigthInput); // Peso introduzido

      // Verifica se o peso está dentro do limite de 30%
      const lowerLimit = expectedWeight * 0.7; // 30% inferior
      const upperLimit = expectedWeight * 1.3; // 30% superior
      console.log(this.articles);

      if (weightInput < lowerLimit || weightInput > upperLimit) {
        // Solicita justificação ao usuário
        //TODO - adicionar peso dos produtos ao alerta
        let productsInfo = '';

        for (const art of this.articles) {
          console.log(art);
          productsInfo += `<p>${art.name} - <strong>${art.sku}</strong> - <strong style="color: red;">${art.weight}g</strong></p>`;
        }

        await this.$swal({
          title: 'Justificação para a diferença de peso',
          html: `
        <p>O peso introduzido é <strong style="color: red;">${weightInput} Kg</strong>.</p>
        <p>O peso esperado é <strong style="color: red;">${expectedWeight} Kg</strong>.</p>
        <p>Os produtos da encomenda são:</p>
        ${productsInfo}
        <input type="text" id="weight_justification" class="form-control" placeholder="Justificação">
      `,
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar',
          showCancelButton: true,
          preConfirm: () => {
            const justification = this.$swal
              .getPopup()
              .querySelector('#weight_justification').value;
            if (!justification) {
              this.$swal.showValidationMessage(
                'Por favor, forneça uma justificação'
              );
            }
            return justification;
          },
        }).then((result2) => {
          if (result2.isConfirmed) {
            this.weight_justification = result2.value; // Armazenar a justificação
          } else {
            canProceed = false;
          }
        });
      }

      // Prossegue normalmente após a justificação (ou se dentro dos limites)
      if (canProceed) {
        if (this.order[0].country === 'PT') {
          this.cttExpresso();
        } else {
          this.cttExpressoInternational();
        }
      }
    },
    async cttExpressoInternational() {
      let advance = true;
      if (this.weigthInput > 20) {
        this.$swal({
          icon: 'warning',
          title: 'Tem a certeza que a encomenda tem mais de 20kg?',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim, continuar!',
          cancelButtonText: 'Cancelar',
        }).then((result) => {
          if (result.isConfirmed) {
            advance = true;
          } else {
            advance = false;
          }
        });
      }
      if (advance == true) {
        this.error = false;
        console.log(this.order);
        console.log('CTTEXPRESSO');
        this.customsDatas = '';
        /*       for (let i = 0; i < this.products.length; i++) {
        console.log(this.products); */
        for (let j = 0; j < this.articles.length; j++) {
          let product = this.articles[j];
          let productName = this.articles[j].name;
          if (this.articles[j].name.length > 50) {
            productName = this.articles[j].name.substring(0, 50);
          }
          productName = productName.replace('&', '');
          console.log(productName);
          let object = `<ctt1:CustomsItemsData>
                <ctt1:Currency>${this.order[0].coin}</ctt1:Currency>
                <ctt1:Detail>${productName}</ctt1:Detail>
                <ctt1:HarmonizedCode>${product.hscode}</ctt1:HarmonizedCode>
                <ctt1:ItemNumber>${j + 1}</ctt1:ItemNumber>
                <ctt1:OriginCountry>${product.country}</ctt1:OriginCountry>
                <ctt1:Quantity>${this.articles[j].quantity}</ctt1:Quantity>
                <ctt1:Value>${Number(this.articles[j].price)}</ctt1:Value>
                <ctt1:Weight>${Number(product.weight) / 1000}</ctt1:Weight>
                </ctt1:CustomsItemsData>`;

          this.customsDatas = this.customsDatas + object;
          console.log(this.customsDatas);
          /*        } */
          console.log(this.customsDatas);
        }
        this.loading = true;
        try {
          console.log('PAÍS:', this.country);
          let x = await this.$store.dispatch(
            'shipmentCTTExpressoInternational',
            {
              orders: this.order,
              customsItems: this.customsDatas,
              fullName: `${this.firstName} ${this.secondName}`,
              cityName: this.city,
              postalCode: this.postalCode,
              addressLine: this.finalAdress,
              weight: this.weigthInput,
              length: this.length,
              width: this.width,
              height: this.height,
              phone: this.phone,
              totalProducts: this.articles.length,
              description: this.description,
              country: this.country,
              orderId: this.orderId,
              shippingMaterial: this.selectedMaterials,
              weight_justification: this.weight_justification,
            }
          );
          console.log(x);
          await this.$parent.getOrder();
        } catch (err) {
          this.error = true;
          console.log('AQUIIIII', err);

          this.$swal({
            icon: 'error',
            title: 'Ooops',
            text: err,
          });

          this.loading = false;
        } finally {
          if (this.error == true) {
            this.loading = false;
            /* this.$parent.getCallDHLLabel(this.dhlExp, this.tracking, false); */
          } else {
            console.log(this.cttExpressoExp);
            this.loading = false;
            this.$parent.getCallCTTEXPRESSOLabel(this.getCTTExpressoExpedition);
          }
        }
      }
    },
    async cttExpresso48() {
      this.customsDatas = '';
      for (let j = 0; j < this.articles.length; j++) {
        let product = this.articles[j];
        let productName = this.articles[j].name;
        if (this.articles[j].name.length > 50) {
          productName = this.articles[j].name.substring(0, 50);
        }
        productName = productName.replace('&', '');
        console.log(productName);
        let object = `<ctt1:CustomsItemsData>
                <ctt1:Currency>${this.order[0].coin}</ctt1:Currency>
                <ctt1:Detail>${productName}</ctt1:Detail>
                <ctt1:HarmonizedCode>${product.hscode}</ctt1:HarmonizedCode>
                <ctt1:ItemNumber>${j + 1}</ctt1:ItemNumber>
                <ctt1:OriginCountry>${product.country}</ctt1:OriginCountry>
                <ctt1:Quantity>${this.articles[j].quantity}</ctt1:Quantity>
                <ctt1:Value>${Number(this.articles[j].price)}</ctt1:Value>
                <ctt1:Weight>${Number(product.weight) / 1000}</ctt1:Weight>
                </ctt1:CustomsItemsData>`;

        this.customsDatas = this.customsDatas + object;
        console.log(this.customsDatas);
        /*        } */
        console.log(this.customsDatas);
      }
      try {
        await this.$store.dispatch('shipmentCTTExpresso48', {
          orders: this.order,
          weight: this.weigthInput,
          fullName: `${this.firstName} ${this.secondName}`,
          cityName: this.city,
          postalCode: this.postalCode,
          addressLine: this.finalAdress,
          phone: this.phone,
          description: this.description,
          orderId: this.orderId,
          length: this.length,
          width: this.width,
          height: this.height,
          customsItems: this.customsDatas,
          totalProducts: this.articles.length,
          country: this.country,
          shippingMaterial: this.selectedMaterials,
          weight_justification: this.weight_justification,
        });
      } catch (err) {
        console.log(err);
      }
    },
    async cttExpresso() {
      let advance = true;
      if (this.weigthInput > 20) {
        this.$swal({
          icon: 'warning',
          title: 'Tem a certeza que a encomenda tem mais de 20kg?',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim, continuar!',
          cancelButtonText: 'Cancelar',
        }).then((result) => {
          if (result.isConfirmed) {
            advance = true;
          } else {
            advance = false;
          }
        });
      }
      if (advance == true) {
        this.error = false;
        console.log(this.order);
        console.log('CTTEXPRESSO');
        console.log(this.weigthInput);
        this.loading = true;
        try {
          await this.$store.dispatch('shipmentCTTExpresso', {
            orders: this.order,
            weight: this.weigthInput,
            fullName: `${this.firstName} ${this.secondName}`,
            cityName: this.city,
            postalCode: this.postalCode,
            addressLine: this.finalAdress,
            phone: this.phone,
            description: this.description,
            orderId: this.orderId,
            length: this.length,
            width: this.width,
            height: this.height,
            shippingMaterial: this.selectedMaterials,
            weight_justification: this.weight_justification,
          });
          await this.$parent.getOrder();
        } catch (err) {
          this.error = true;
          console.log('AQUIIIII', err);
          this.$swal({
            icon: 'error',
            title: 'Ooops',
            text: err,
          });

          this.loading = false;
        } finally {
          if (this.error == true) {
            this.loading = false;
            /* this.$parent.getCallDHLLabel(this.dhlExp, this.tracking, false); */
          } else {
            this.loading = false;
            this.$parent.getCallCTTEXPRESSOLabel(this.getCTTExpressoExpedition);
          }
          alert('ACABOU');
        }
      }
    },
    async getAllProducts() {
      console.log(this.comunitary);
      try {
        await this.$store.dispatch('fetchProducts');
        this.allProducts = this.getProducts.data;
        this.productsOrder();
      } catch (err) {
        this.$swal({
          icon: 'error',
          title: 'Ooops',
          text: err,
        });
      }
    },
    async productsOrder() {
      console.log('ENTROU');
      this.products = [];
      for (let index = 0; index < this.articles.length; index++) {
        console.log(this.articles.length - 1, index);
        let product = this.allProducts.find((product) =>
          product.sku.includes(this.articles[index].sku)
        );
        /* if (
          (product.hscode == null || product.country == null) &&
          this.comunitary == false
        ) {
          this.form = "block";
        } */
        this.form = 'block';
        this.products.push(product);
      }
      console.log(this.products);
    },
    async updateCountry() {
      this.country = this.selectCountry;
      this.$refs['my-modal'].hide();
    },
    async allCountries() {
      this.selectCountry = this.country;
      console.log(this.selectCountry);
      try {
        await this.$store.dispatch('fetchAllCountries');
        this.countries = this.getAllCountries;
        console.log(this.getAllCountries);
        this.$refs['my-modal'].show();
      } catch (err) {
        alert(err);
      }
    },
    async updateProducts() {
      try {
        await this.$store.dispatch('updateProducts');
      } catch (err) {
        alert(err);
      }
    },
    //Translate
    async translate(address, city, state) {
      try {
        await this.$store.dispatch('translateOrderInfo', {
          address: address,
          city: city,
          state: state,
        });
        let translated = this.getTranslated;
        this.address_translate = translated.address;
        this.city_translate = translated.city;
        this.state_translate = translated.state;
        this.$refs['modal_translate'].show();
      } catch (err) {
        console.log(err);
      }
    },
    async updateAddressTranslated() {
      if (this.accept_address == true) {
        this.finalAdress = this.address_translate;
      }

      if (this.accept_city == true) {
        this.city = this.city_translate;
      }

      if (this.accept_state == true) {
        this.state = this.state_translate;
      }

      this.$refs['modal_translate'].hide();
      this.address_translate = '';
      this.city_translate = '';
      this.state_translate = '';
      this.accept_address = true;
      this.accept_city = true;
      this.accept_state = true;
    },
    //Product Material
    async getProductTranMaterialF() {
      try {
        await this.$store.dispatch('getProductsTranMaterial', {
          id: this.order[0].id,
        });
        this.productTranMaterial = this.getProductTranMaterial.mat;
        this.finalMessage = this.getProductTranMaterial.message;
        console.log(this.productTranMaterial);
      } catch (err) {
        this.$swal({
          icon: 'error',
          title: 'Ooops',
          text: err,
        });
      }
    },
    toggleMaterial(sku) {
      const material = this.productTranMaterial.find((mat) => mat.sku === sku);

      // Limpa todas as seleções anteriores, seja caixa com dimensões ou produtos sem dimensões
      this.selectedMaterials = [];

      if (material && material.dimensions) {
        // Se a caixa já estiver selecionada, desmarca-a e limpa as dimensões
        if (this.selectedMaterials.includes(sku)) {
          // Limpa as dimensões ao desmarcar a caixa
          this.length = null;
          this.width = null;
          this.height = null;
        } else {
          // Adiciona a nova caixa e atribui suas dimensões
          this.selectedMaterials.push(sku);
          this.length = material.dimensions.length;
          this.width = material.dimensions.width;
          this.height = material.dimensions.height;
        }
      } else {
        // Para produtos sem dimensões (envelopes, sacos, etc.), adiciona à lista
        this.selectedMaterials.push(sku);

        // Limpa as dimensões, pois não é uma caixa
        this.length = null;
        this.width = null;
        this.height = null;
      }
    },
  },
  computed: {
    ...mapGetters(['getProducts']),
    ...mapGetters(['getCTTExpressoExpedition']),
    ...mapGetters(['getAllCountries']),
    ...mapGetters(['getTranslated']),
    ...mapGetters(['getProductTranMaterial']),
  },
  created() {
    // this.getAllProducts();
    this.getProductTranMaterialF();
  },
};
</script>

<style scoped>
.card {
  padding: 25px;
  background-color: rgba(255, 147, 147, 0.733);
  margin: 50px;
  border: 1px solid #dddd;
  border-radius: 18px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.row {
  margin-bottom: 15px;
}
hr {
  background-color: white;
  height: 2px;
  border: 0;
}
h3 {
  color: white;
}
#send {
  text-align: right;
}
#btnUpdateCountry {
  text-align: center;
}
.canceled {
  background-color: red;
  border-color: rgb(255, 0, 0);
}

#aUpdateAddress {
  margin-left: 5px;
  cursor: pointer;
}
#aUpdateAddress:hover {
  cursor: pointer;
}

.material-container {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.material-box {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  background-color: #f9f9f9;
  text-align: center;
  width: 150px; /* largura fixa para as caixas */
}

.material-box:hover {
  background-color: #e0e0e0;
}

.material-box.selected {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

@media (max-width: 600px) {
  .material-box {
    width: 100%; /* Em telas menores, as caixas ocuparão toda a largura */
  }
}
</style>
