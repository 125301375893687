<template>
  <div>
    <span id="loading" v-if="this.loading == true">
      <Loading />
    </span>
    <div class="card">
      <h3 class="title">Encomenda nº: {{ orderId }}</h3>
      <hr />
      <div class="row">
        <div class="col">
          <h5>Transportadora:</h5>
          <h3>CTT Expresso</h3>
        </div>
        <div class="col">
          <h5>Expedição nº:</h5>
          <h3>{{ expedition }}</h3>
        </div>
        <div class="col">
          <h5>Tracking:</h5>
          <a @click="openTracking(expedition)"
            ><h3>{{ expedition }}</h3></a
          >
        </div>
        <div class="col">
          <h5>Etiqueta</h5>
          <button class="btn btn-primary mb-2" @click="getTag()">
            Etiqueta
          </button>
        </div>
        <div class="col">
          <h5>Cancelar</h5>
          <p><b>Para cancelar não entregue a encomenda ao estafeta</b></p>
        </div>
        <div class="col">
          <h5>Fatura</h5>
          <span v-if="this.order[0].id_invoice == 0"
            >Fatura criada Manualmente</span
          >
          <span v-else>
            <button
              class="btn btn-primary mb-2"
              @click="openFile(order[0].id_invoice)"
            >
              {{ this.order[0].id_invoice }}
            </button>
          </span>
        </div>
        <div
          class="col"
          v-if="
            this.order[0].send_email == '' || this.order[0].send_email == null
          "
        >
          <button class="btn btn-primary mb-2" @click="sendEmail()">
            Enviar Email
          </button>
        </div>
        <div v-else class="col">
          <h5>Email enviado</h5>
          <button class="btn btn-primary mb-2" @click="sendEmail(true)">
            Reenviar Email
          </button>
        </div>
        <div class="col">
          <h5>Nota de Crédito</h5>
          <button
            class="btn btn-info"
            v-if="role == 'admin' && this.order[0].credit_note_id == null"
            @click="creditNoteCreation(order[0].id, order[0].id_invoice)"
          >
            Fazer nota de crédito
          </button>
          <button
            class="btn btn-primary"
            v-else-if="this.order[0].credit_note_id != null"
          >
            {{ this.order[0].credit_note_id }}
          </button>
          <span v-else><b>Não existe Nota de Crédito</b></span>
        </div>
      </div>
    </div>
    <div class="card">
      <table>
        <td><h3 class="title">Estados do tracking</h3></td>
        <td>
          <h5 class="title" v-if="trackingStatus.delivered_status == true">
            <b style="background-color: green">Entregue</b>
          </h5>
          <b-button
            v-else
            @click="confirmDelivered()"
            class="btn btn-secondary mb-2"
            >Marcar como entregue</b-button
          >
        </td>
      </table>
      <hr v-if="this.order[0].insurance != null" />
      <h4 v-if="this.order[0].insurance != null">
        <u>Seguro Recebido:</u> <b>{{ this.order[0].insurance }} €</b>
      </h4>
      <hr />
      <table>
        <td>
          <b>Data de Envio:</b> {{ splitDate2(trackingStatus.send_date) }}
        </td>
        <td v-if="trackingStatus.max_date != null">
          <b>Data máx. entrega:</b> {{ splitDate2(trackingStatus.max_date) }}
        </td>
        <td v-if="trackingStatus.email_date != null">
          <b>Data email enviado:</b> {{ splitDate2(trackingStatus.email_date) }}
        </td>
        <td v-if="trackingStatus.delivered_status != true">
          <button class="btn btn-primary mb-2" @click="sendEmailTrans()">
            Enviar Email Transportadora
          </button>
        </td>
      </table>
      <hr />
      <span v-if="trackingStatusMovements.length == 0" style="font-size: larger"
        ><b>Sem movimentos</b>
      </span>
      <table v-else>
        <tr>
          <th>Data</th>
          <th>Estado</th>
          <th>Des. do Estado</th>
        </tr>
        <tr v-for="status in trackingStatusMovements" :key="status.code">
          <td>{{ splitDate(status.date) }}</td>
          <td>{{ status.event }}</td>
          <td>{{ status.code_des }}</td>
          <hr />
        </tr>
      </table>
      <hr />
      <div class="return" v-if="this.order[0].status == 'completed'">
        <span v-if="splitDate2(trackingStatus.send_date) == dateNow"
          ><button class="btn btn-primary" @click="internReturn()">
            Devolução Interna
          </button></span
        >
        <span v-else
          ><button class="btn btn-primary" @click="openModal()">
            Encomenda Devolvida
          </button></span
        >
      </div>
      <div v-else-if="this.order[0].status == 'returned'">
        <h5><b>Devolução</b></h5>
        <br />
        <button
          id="returnedProducts"
          class="btn btn-info"
          @click="returnedProducts()"
        >
          Ver produtos devolvidos</button
        ><br />
        <button
          class="btn btn-success"
          id="processing"
          @click="updateOrder('processing')"
        >
          Para Processamento
        </button>
        <button
          class="btn btn-primary"
          id="finish"
          @click="updateOrder('finish')"
        >
          Finalizar
        </button>
      </div>
      <div v-else-if="this.order[0].status == 'confirmed/returned'">
        <h5>Encomenda devolvida com sucesso</h5>
      </div>
      <div
        v-else-if="
          this.order[0].status == 'lost_with_insurance' ||
          this.order[0].status == 'lost_without_insurance'
        "
      >
        <h5>Quer criar nova encomenda?</h5>
        <button
          class="btn btn-success"
          id="processing"
          @click="duplicateOrder(true)"
        >
          Sim
        </button>
        <button
          class="btn btn-warning"
          id="processing"
          @click="duplicateOrder(false)"
        >
          Não
        </button>
        <br />
        <button class="btn btn-primary" @click="alertToPassToReturn()">
          Encomenda Devolvida
        </button>
      </div>
    </div>
    <b-modal
      ref="modal_return"
      id="modal-4"
      title="Devolução "
      size="xl"
      hide-footer
    >
      <table class="table">
        <thead>
          <tr>
            <th>Quantidade</th>

            <th>SKU/CNP</th>

            <th>Nome</th>

            <th>Validade</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(article, index) of allArticles" :key="article">
            <td style="width: 10%">
              <input
                type="number"
                class="form-control mb-2"
                v-model="allArticles[index].quantity"
              />
            </td>
            <td>
              <a
                id="openArticle"
                v-b-modal.modal-3
                @click="passProductData(article)"
                >{{ article.sku }}</a
              >
            </td>
            <td>{{ article.name }}</td>
            <td>
              <date-picker
                v-model="products[index].validity"
                type="month"
                format="YYYY-MM"
                value-type="format"
                :lang="lang"
                required
              ></date-picker>
              <span id="infinity" @click="setDataInfinity(products[index])">
                Não tem validade
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <div>
        <label for="">Comentários</label>
        <textarea class="form-control" rows="2" v-model="comment"></textarea>
      </div>
      <button class="btn btn-primary float-right" @click="confirmModal()">
        Confirmar
      </button>
    </b-modal>
    <b-modal
      ref="modal_product"
      id="modal-3"
      title="Produto"
      size="lg"
      hide-footer
    >
      <div class="card">
        <b-container>
          <b-row align-v="center"
            ><b-col class="left">
              <!-- IMAGEM DO PRODUTO -->
              <img :src="imgProduct" />
            </b-col>
            <b-col>
              <p><span id="subtitle">STOCK: </span> {{ stockProduct }}</p>
              <p><span id="subtitle">EAN:</span> {{ eanProduct }}</p>
              <p><span id="subtitle">SKU:</span> {{ skuProduct }}</p>
              <p><span id="subtitle">HS Code:</span> {{ hsProduct }}</p>
              <p>
                <span id="subtitle">Notas Arm.:</span>
                {{ warehouseProduct }}
              </p>
              <p><span id="subtitle">Notas:</span> {{ notesProduct }}</p>
              <p><span id="subtitle">Peso:</span> {{ weightProduct }} (g)</p>
            </b-col></b-row
          >
        </b-container>
        <button class="btn btn-info" @click="openProdPage()">
          Editar Produto
        </button>
      </div>
    </b-modal>
    <b-modal
      ref="modal_returned_products"
      id="modal-5"
      title="Produtos devolvidos"
      size="lg"
      hide-footer
    >
      <div v-for="prod of allReturnedProducts" :key="prod.id">
        <p>
          <b>{{ prod.sku }}</b> - <u>{{ prod.quantity }}x</u> {{ prod.name }}
        </p>
        <hr />
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Loading from '@/components/Loading.vue';
export default {
  components: {
    Loading,
  },
  props: {
    orderId: String,
    expedition: String,
    articles: Array,
    order: Array,
    tracking: String,
    xml: Boolean,
    price_eur: Number,
  },
  data: function () {
    return {
      xmlCode: '',
      allProducts: [],
      products: [],
      form: 'none',
      name: {},
      loading: false,
      pautalCode: [],
      allArticles: JSON.parse(this.order[0].articles),
      eanProduct: '',
      skuProduct: '',
      hsProduct: '',
      warehouseProduct: '',
      notesProduct: '',
      weightProduct: '',
      imgProduct: '',
      idProduct: '',
      stockProduct: '',
      comment: '',
      trackingStatus: '',
      trackingStatusMovements: '',
      allReturnedProducts: [],
      dateNow: '',
      role: '',
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
          months: [
            'janeiro',
            'fevereiro',
            'março',
            'abril',
            'maio',
            'junho',
            'julho',
            'agosto',
            'setembro',
            'outubro',
            'novembro',
            'dezembro',
          ],
          // MMM
          monthsShort: [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Out',
            'Nov',
            'Dez',
          ],
          // dddd
          weekdays: [
            'Domingo',
            'Segunda-feira',
            'Terça-feira',
            'Quarta-feira',
            'Quinta-feira',
            'Sexta-feira',
            'Sábado',
          ],
          // ddd
          weekdaysShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
          // dd
          weekdaysMin: ['Do', 'Se', 'Te', 'Qa', 'Qi', 'Se', 'Sa'],
        },
      },
    };
  },
  methods: {
    async openProdPage() {
      window.open(
        `https://${window.location.host}/product/${this.idProduct}`,
        '_blank'
      );
      this.$refs['modal_product'].hide();
    },
    async changeStatus() {
      this.$swal({
        title: 'Esta encomenda ficará como tratada',
        text: 'Caso aceite esta encomenda ficará como tratada sem ser gerada uma nova encomenda!',
        showCancelButton: true,
        confirmButtonText: 'Sim',
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$store.dispatch('statusUpdate', {
              id: this.orderId,
              status: `${this.order[0].status}/confirmed`,
            });
            this.$swal.fire(`Encomenda tratada`, '', 'success');
          } catch (err) {
            alert(err);
          }
        }
      });
    },
    async duplicateOrder(newInvoice) {
      await this.$swal({
        title: 'Quer criar uma nota de crédito?',
        icon: 'question',
        showDenyButton: true,
        denyButtonText: 'Não',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        denyButtonColor: '#d33',
        confirmButtonText: 'Sim, quero!',
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.$swal({
            title: 'Será criada uma nova encomenda e gerada nota de crédito',
            html: `<input type="text" id="justification" class="form-control" placeholder="Notas" required>`,
            showCancelButton: true,
            confirmButtonText: 'Sim',
            preConfirm: () => {
              const justification =
                document.getElementById('justification').value;
              if (!justification) {
                this.$swal.showValidationMessage('Justificação necessária');
              }
              return { justification: justification };
            },
          }).then(async (result) => {
            let justification = result.value.justification;
            if (result.isConfirmed) {
              try {
                await this.$store.dispatch('duplicateOrder', {
                  id: this.orderId,
                  newInvoice: newInvoice,
                  notes: justification,
                  creditNote: true,
                });
                if (newInvoice) {
                  this.$swal.fire(
                    `Encomenda P-${this.orderId} criada com nota de crédito`,
                    '',
                    'success'
                  );
                } else {
                  this.$swal.fire(
                    `Nota de Créidto criada para a encomenda ${this.orderId}`,
                    '',
                    'success'
                  );
                }
              } catch (err) {
                alert(err);
              }
            }
          });
        } else if (result.isDenied) {
          try {
            await this.$store.dispatch('duplicateOrder', {
              id: this.orderId,
              newInvoice: newInvoice,
              notes: '',
              creditNote: false,
            });
            if (newInvoice) {
              this.$swal.fire(
                `Encomenda P-${this.orderId} criada sem nota de crédito`,
                '',
                'success'
              );
            } else {
              this.$swal.fire(`Encomenda tratada`, '', 'success');
              this.order[0].status = 'confirmed';
            }
          } catch (err) {
            alert(err);
          }
        }
      });
    },
    async getTag() {
      console.log(this.order[0]);
      await this.$store.dispatch('labelCTTExpresso', {
        expedition: this.expedition,
      });
      window.open(
        'data:application/pdf;base64,' + this.getCTTExpressoLabel.data[0].label
      );
      console.log(this.articles);
    },
    async setDataInfinity(product) {
      console.log('Infinito');
      product.validity = '2050-01';
      console.log(product);
    },
    /* OBTER TODOS OS PRODUTOS */
    async getAllProducts() {
      console.log(this.xml);
      try {
        await this.$store.dispatch('fetchProducts');
        this.allProducts = this.getProducts.data;
      } catch (err) {
        alert(err);
      }
    },
    async sendEmail(resend = false) {
      this.loading = true;
      console.log(this.order[0].email);
      console.log(this.order[0]);
      try {
        await this.$store.dispatch('fetchEmail', {
          order: this.order[0],
          company: 'cttExpresso',
          trackingId: this.expedition,
          resend: resend,
          price_eur: this.price_eur,
        });
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'success',
          title: 'Email enviado!!',
          showConfirmButton: false,
          timer: 1500,
        });
      } catch (err) {
        console.log(err);
        this.loading = false;
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'error',
          title: err,
          showConfirmButton: false,
          timer: 1500,
        });
      } finally {
        await this.$parent.getOrder();
        this.loading = false;
      }
    },
    async openFile(id) {
      console.log(id);
      try {
        await this.$store.dispatch('fetchPDFLink', {
          id: id,
        });
      } catch (err) {
        console.log(err);
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'error',
          title: err,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    async getTracking_fun() {
      const id = this.$route.params.id;
      try {
        await this.$store.dispatch('getTracking', {
          order: id,
        });
        this.trackingStatus = this.getTracking;
        this.trackingStatusMovements = this.getTracking.movements;
      } catch (err) {
        console.log(err);
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'error',
          title: err,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    async sendEmailTrans() {
      const id = this.$route.params.id;
      try {
        await this.$store.dispatch('sendEmailTransport', {
          id: id,
        });
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'success',
          title: 'Email Enviado',
          showConfirmButton: false,
          timer: 1500,
        });
        await this.getTracking_fun();
      } catch (err) {
        console.log(err);
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'error',
          title: err,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    splitDate(date) {
      let split = date.split('T');
      let split2 = split[1].split('.');
      return split[0] + ' ' + split2[0];
    },
    splitDate2(date) {
      let split = date.split('T');
      return split[0];
    },
    async passProductData(data) {
      console.log(data);
      const filterProduct = this.allProducts.filter((product) =>
        product.sku.includes(data.sku)
      );
      console.log(filterProduct);
      this.eanProduct = filterProduct[0].ean;
      this.skuProduct = filterProduct[0].sku;
      this.hsProduct = filterProduct[0].hscode;
      this.warehouseProduct = filterProduct[0].note_warehouse;
      this.notesProduct = filterProduct[0].note_finish;
      this.weightProduct = filterProduct[0].weight;
      this.imgProduct = filterProduct[0].img;
      this.idProduct = filterProduct[0].id_backoffice;
      this.stockProduct = filterProduct[0].stock;
      console.log(this.eanProduct);
      console.log(this.skuProduct);
      console.log(filterProduct, data);
    },
    async confirmModal() {
      await this.$swal({
        title: 'Confirma que esta encomenda é uma devolução?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, confirmo!',
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$store.dispatch('returnedOrder', {
              id: this.orderId,
              articles: this.allArticles,
              comment: this.comment,
              validity: this.products,
            });
            console.log(this.allArticles);
            console.log('Acabouuu!');
            this.$swal({
              toast: true,
              position: 'top-end',
              icon: 'success',
              title: `Encomenda ${this.orderId} devolvida!`,
              showConfirmButton: false,
              timer: 1500,
            });
            this.order[0].status = 'returned';
            this.$refs['modal_return'].hide();
          } catch (err) {
            console.log(err);
          }
        }
      });
    },
    async productsOrder() {
      this.products = [];
      let validity = '';
      for (let index = 0; index < this.allArticles.length; index++) {
        /* console.log(this.articles.length - 1, index); */
        let product = this.allArticles[index];
        if (product.validity != null) {
          console.log(product.validity);
          validity = product.validity.substring(0, 7);
          if (validity != '2050-01') {
            validity = '';
          }
        }
        this.products.push({ sku: product.sku, validity: validity });
      }
      console.log(this.products);
    },
    openModal() {
      this.$refs['modal_return'].show();
    },
    async updateOrder(status) {
      let title =
        "Confirma que esta encomenda irá para o estado 'Em processamento'";
      if (status == 'finish') {
        title =
          "Confirma que esta encomenda irá para o estado 'Completa/Devolvida'";
      }
      await this.$swal({
        title: 'Quer criar uma nota de crédito?',
        icon: 'question',
        showDenyButton: true,
        denyButtonText: 'Não',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        denyButtonColor: '#d33',
        confirmButtonText: 'Sim, quero!',
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.$swal({
            title: title,
            html: `<p>Irá ser feita nota de crédito da encomenda ${this.orderId}<p><input type="text" id="justification" class="form-control" placeholder="Notas" required>`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim, confirmo!',
            cancelButtonText: 'Cancelar',
            preConfirm: () => {
              const justification =
                document.getElementById('justification').value;
              if (!justification) {
                this.$swal.showValidationMessage('Justificação necessária');
              }
              return { justification: justification };
            },
          }).then(async (result) => {
            let justification = result.value.justification;
            if (result.isConfirmed) {
              // TODO: fazer nota de crédito
              try {
                await this.$store.dispatch('updateStatusOrder', {
                  id: this.orderId,
                  orderData: this.order,
                  status: status,
                  notes: justification,
                  creditNote: true,
                });
                this.$swal({
                  toast: true,
                  position: 'top-end',
                  icon: 'success',
                  title: `Encomenda ${this.orderId} atualizada com sucesso!`,
                  showConfirmButton: false,
                  timer: 1500,
                });
              } catch (err) {
                alert(err);
              }
              this.order[0].status = 'confirmed/returned';
            }
          });
        }
        if (result.isDenied) {
          try {
            await this.$store.dispatch('updateStatusOrder', {
              id: this.orderId,
              orderData: this.order,
              status: status,
              notes: '',
              creditNote: false,
            });
            this.$swal({
              toast: true,
              position: 'top-end',
              icon: 'success',
              title: `Encomenda ${this.orderId} atualizada com sucesso!`,
              showConfirmButton: false,
              timer: 1500,
            });
            this.order[0].status = 'confirmed/returned';
          } catch (err) {
            alert(err);
          }
        }
      });
    },
    async openTracking(tracking) {
      window.open(`https://www.ordertracker.com/track/${tracking}`, '_blank');
    },
    async setOrderDelivered() {
      try {
        await this.$store.dispatch('setOrderDelivered', {
          order_id: this.order[0].id,
        });
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'success',
          title: 'Estado alterado para entregue',
          showConfirmButton: false,
          timer: 1500,
        });
        location.reload();
      } catch (err) {
        console.log(err);
        alert(err);
      }
    },
    async confirmDelivered(order) {
      this.$swal({
        title:
          'Quer colocar a encomenda ' + this.order[0].id + ' como entregue?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim!',
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.setOrderDelivered(order);
        }
      });
    },
    async returnedProducts() {
      const id = this.$route.params.id;
      this.$refs['modal_returned_products'].show();
      try {
        await this.$store.dispatch('returnedProducts', {
          idOrder: id,
        });
        this.allReturnedProducts = this.getReturnedProducts;
        console.log(this.allReturnedProducts);
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'success',
          title: 'Encomenda Devolvida',
          showConfirmButton: false,
          timer: 1500,
        });
        location.reload();
      } catch (err) {
        console.log(err);
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'error',
          title: err,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    async date() {
      let newDate = new Date();
      let day = ('0' + newDate.getDate()).slice(-2);
      let month = ('0' + (newDate.getMonth() + 1)).slice(-2);
      let year = newDate.getFullYear();
      this.dateNow = `${year}-${month}-${day}`;
    },
    async internReturn() {
      let advance;
      let justification = ''; // Variável para armazenar a justificativa

      await this.$swal({
        title: 'Quer fazer Devolução Interna?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, quero!',
        cancelButtonText: 'Cancelar',
      }).then(async (result2) => {
        if (result2.isConfirmed) {
          advance = true;
        } else {
          advance = false;
        }
      });

      if (advance === true) {
        await this.$swal({
          title: 'Será criada uma nota de crédito!',
          icon: 'question',
          html: `<input type="text" id="justification-input" class="swal2-input" placeholder="Escreva a justificação">`, // Caixa de texto para justificativa
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Avançar',
          cancelButtonText: 'Cancelar!',
          preConfirm: () => {
            justification = document.getElementById(
              'justification-input'
            ).value;
            if (!justification) {
              this.$swal.showValidationMessage('A justificação é obrigatória');
            }
            return justification;
          },
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              await this.$store.dispatch('internReturn', {
                id: this.orderId,
                newInvoice: true,
                justification: justification, // Passa a justificativa para a ação
              });
              this.$swal({
                toast: true,
                position: 'top-end',
                icon: 'success',
                title: 'Encomenda Devolvida',
                showConfirmButton: false,
                timer: 1500,
              });
              location.reload();
            } catch (err) {
              alert(err);
            }
          }
        });
      }
    },

    async userRole() {
      this.role = JSON.parse(localStorage.getItem('user')).role;
    },
    async creditNoteCreation(idOrder, idInvoice) {
      this.$swal({
        title: 'Quer criar uma nota de crédito?',
        html: `<input type="text" id="justification" class="form-control" placeholder="Notas" required>`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, quero!',
        cancelButtonText: 'Cancelar',
        preConfirm: () => {
          const justification = document.getElementById('justification').value;
          if (!justification) {
            this.$swal.showValidationMessage('Justificação necessária');
          }
          return { justification: justification };
        },
      }).then(async (result) => {
        let justification = result.value.justification;
        if (result.isConfirmed) {
          try {
            await this.$store.dispatch('createCreditNote', {
              idOrder: idOrder,
              idInvoice: idInvoice,
              notes: justification,
            });
            this.$swal({
              toast: true,
              position: 'top-end',
              icon: 'success',
              title: 'Nota de crédito criada',
              showConfirmButton: false,
              timer: 1500,
            });
          } catch (err) {
            alert(err);
          }
        }
      });
    },
    async alertToPassToReturn() {
      this.$swal({
        title:
          'A encomenda foi dada como Perdida. Tem a certeza que quer passa-la para Devolvida?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, quero!',
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.openModal();
        }
      });
    },
  },
  async created() {
    this.loading = true;
    await this.getTracking_fun();
    // await this.getAllProducts();
    await this.productsOrder();
    await this.date();
    await this.userRole();
    if (this.order[0].send_email == '' || this.order[0].send_email == null)
      await this.sendEmail();
    this.loading = false;
  },
  computed: {
    ...mapGetters(['getCTTExpressoLabel']),
    ...mapGetters(['getTracking']),
    ...mapGetters(['getProducts']),
    ...mapGetters(['getReturnedProducts']),
  },
};
</script>

<style scoped>
.card {
  padding: 25px;
  background-color: rgba(255, 147, 147, 0.733);
  margin: 50px;
  border: 1px solid #dddd;
  border-radius: 18px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  text-align: center;
}
.title {
  text-align: left;
  color: white;
}
h5 {
  color: white;
}
label {
  text-align: right;
}
#titleForm {
  text-align: left;
  color: white;
}
#send {
  text-align: right;
}
.form-group {
  margin: 20px;
}
a h3 {
  text-decoration: underline;
}
a h3:hover {
  text-decoration: none;
  cursor: pointer;
}
img {
  max-width: 100%;
  border-radius: 18px;
}
#subtitle {
  color: white;
  font-weight: bold;
}
#openArticle {
  color: rgb(0, 98, 255);
}
#openArticle:hover {
  text-decoration: underline;
}
#processing {
  margin-right: 10px;
}
#finish {
  margin-left: 10px;
}
textarea {
  resize: none;
  margin-bottom: 20px;
}
#returnComment {
  margin-top: 10px;
}
#infinity:hover {
  cursor: pointer;
  color: aqua;
}
#returnedProducts {
  margin-bottom: 10px;
}
</style>
