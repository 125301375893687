<template>
  <!-- COMPONENTE ONDE SURGE OS PRODUTOS DE CADA ENCOMENDA -->
  <div class="card">
    <span id="loading" v-if="this.loading == true">
      <Loading />
    </span>
    <h3>Encomenda nº {{ orderId }}</h3>
    <!-- FORMULÁRIO PARA FAZER O REGISTO DE UM PRODUTO -->
    <div class="row">
      <div class="col registerInput">
        <form v-on:submit.prevent="registeProduct()" class="form-container">
          <div class="form-group row align-items-center">
            <label
              for="ean"
              id="eanLabel"
              class="col-sm-2 col-form-label text-right font-weight-bold"
              >EAN:</label
            >
            <div class="col-sm-6 input-group">
              <input
                type="text"
                class="form-control form-control-sm rounded"
                id="ean"
                autofocus
                v-model="ean"
                :placeholder="
                  pasteAllowed
                    ? 'Insira ou cole o EAN'
                    : 'Use o leitor de código de barras'
                "
                @input="handleInput"
                @paste="handlePaste($event)"
                ref="eanInput"
              />
              <!-- Ícone para desbloquear colagem -->
              <div class="input-group-append" v-if="!pasteAllowed">
                <span
                  class="input-group-text unlock-icon"
                  title="Desbloquear colagem"
                  @click="openAcceptModal"
                >
                  <i class="fas fa-unlock-alt"></i>
                </span>
              </div>
            </div>
            <div class="col-sm-2 text-center">
              <button type="submit" class="btn btn-primary btn-sm rounded">
                Registar
              </button>
            </div>
          </div>
          <div
            v-if="this.lastRegist"
            class="last-registered text-muted text-center mt-2"
          >
            Último registo: <i>{{ lastRegist }}</i>
          </div>
        </form>

        <!-- Modal para aceitar os riscos -->
        <b-modal ref="acceptModal" title="Desbloquear Colagem" hide-footer>
          <div class="modal-body">
            <p class="text-justify">
              Recomendamos a leitura do código de barras (picagem), que é mais
              segura e eficiente. Caso opte por colar, confirme que entende os
              riscos escrevendo <strong>"S"</strong> no campo abaixo e pressione
              Enter.
            </p>
            <input
              type="text"
              class="form-control"
              v-model="acceptInput"
              placeholder="Digite 'S'"
              @keyup="handleAcceptKeyPress"
            />
          </div>
          <div class="modal-footer text-right">
            <button
              class="btn btn-success btn-sm rounded"
              :disabled="acceptInput.trim().toLowerCase() !== 's'"
              @click="allowPaste"
            >
              Confirmar
            </button>
          </div>
        </b-modal>
      </div>

      <div class="col">
        <div class="row">
          <div class="col">
            <div class="box box1">
              <img :src="img" />
            </div>
          </div>
          <div class="col infoProduct">
            <div class="form-group">
              <div class="input-group mb-2 mr-sm-2">
                <div class="input-group-prepend">
                  <div class="input-group-text">SKU</div>
                </div>
                <input
                  type="text"
                  class="form-control form-control"
                  id="sku"
                  v-model="skuInfo"
                  disabled
                />
              </div>
            </div>
            <div class="form-group">
              <div class="input-group mb-2 mr-sm-2">
                <div class="input-group-prepend">
                  <div class="input-group-text">EAN</div>
                </div>
                <input
                  type="text"
                  class="form-control form-control"
                  id="ean"
                  v-model="eanInfo"
                  disabled
                />
              </div>
            </div>
            <div class="form-group">
              <div class="input-group mb-2 mr-sm-2">
                <div class="input-group-prepend">
                  <div class="input-group-text">Name</div>
                </div>
                <input
                  type="text"
                  class="form-control form-control"
                  id="productName"
                  disabled
                  v-model="nameInfo"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <!-- 3 COLUNAS -->
    <b-container>
      <b-row align-v="center">
        <!-- PRIMEIRA COLUNA SURGE A DATA DA ENCOMENDA -->
        <b-col cols="2" class="left">
          <p>{{ this.order[0].date }}</p>
        </b-col>
        <!-- SEGUNDA COLUNA SURGE A QUANTIDADE DE CADA PRODUTO, O NOME, E O SKU CORRESPONDENTE -->
        <b-col cols="8">
          <span v-for="article in articles" :key="article.id">
            <p>
              {{ article.quantity }} x {{ article.sku }} -<a
                id="openArticle"
                v-b-modal.modal-4
                @click="passProductData(article)"
              >
                {{ article.name }}
              </a>
              <span
                style="color: red"
                v-if="
                  article.notes != null ||
                  article.notes != 'null' ||
                  article.notes != '' ||
                  article.notes != undefined
                "
                >- <b>{{ article.notes }}</b></span
              >
            </p></span
          ></b-col
        >
        <!-- TERCEIRA COLUNA DIZ-NOS QUAIS PROTUDOS JÁ FORAM REGISTADOS -->
        <b-col cols="2">
          <div v-if="this.order[0].status == 'completed'">
            <p class="completed">COMPLETO</p>
          </div>
          <div v-if="this.order[0].status == 'canceled'">
            <p class="completed">CANCELADA</p>
          </div>
          <div v-else-if="this.order[0].status == 'packing'">
            <span v-for="(registed, index) in produtsRegisted" :key="index">
              <p>{{ registed.registed }}/{{ registed.total }}</p>
            </span>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <hr />
    <b-row>
      <b-col cols="10"></b-col>
      <b-col cols="2">
        <span v-if="this.arrayArticles.length == 0 || this.arrayArticles == ''">
          <button
            id="next"
            type="submit"
            class="btn btn-primary mb-2"
            @click="choiceCompany()"
            cols="2"
          >
            Seguinte
          </button></span
        >
        <span v-else
          ><button type="submit" class="btn btn-primary mb-2" cols="2" disabled>
            Seguinte
          </button></span
        ></b-col
      ></b-row
    >
    <!-- MODAL PARA MOSTRAR O PRODUTO -->
    <b-modal
      ref="modal_product"
      id="modal-4"
      title="Produto"
      size="lg"
      hide-footer
    >
      <div class="card">
        <b-container>
          <b-row align-v="center"
            ><b-col class="left">
              <!-- IMAGEM DO PRODUTO -->
              <img :src="imgProduct" />
            </b-col>
            <b-col>
              <p><span id="subtitle">STOCK: </span> {{ stockProduct }}</p>
              <p><span id="subtitle">EAN:</span> {{ eanProduct }}</p>
              <p><span id="subtitle">SKU:</span> {{ skuProduct }}</p>
              <p><span id="subtitle">HS Code:</span> {{ hsProduct }}</p>
              <p>
                <span id="subtitle">Notas Arm.:</span> {{ warehouseProduct }}
              </p>
              <p><span id="subtitle">Notas:</span> {{ notesProduct }}</p>
              <p><span id="subtitle">Peso:</span> {{ weightProduct }} (g)</p>
            </b-col></b-row
          >
        </b-container>
        <button class="btn btn-info" @click="openProdPage()">
          Editar Produto
        </button>
      </div>
    </b-modal>
    <!-- <button @click="cttExpresso()">EXPRESSO</button> -->
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Loading from '@/components/Loading.vue';
export default {
  props: {
    orderId: String,
    articles: Array,
    order: Array,
    allProducts: Array,
  },
  components: {
    Loading,
  },
  data: function () {
    return {
      products: '',
      ean: '',
      filterProducts: '',
      productsArray: [],
      produtsRegisted: [],
      expedition: '',
      loading: false,
      picking: [],
      eans: '',
      lastRegist: '',
      eanProduct: '',
      skuProduct: '',
      hsProduct: '',
      warehouseProduct: '',
      notesProduct: '',
      weightProduct: '',
      imgProduct: '',
      idProduct: '',
      stockProduct: '',
      arrayArticles: [],
      img: 'https://www.svgrepo.com/show/97910/image.svg',
      skuInfo: '',
      eanInfo: '',
      nameInfo: '',
      pasteAllowed: false, // Flag para controlar se a colagem está habilitada
      acceptInput: '', // Input do usuário no modal
      lastKeyTime: 0, // Tempo do último evento
      eanInputBuffer: '', // Buffer temporário para armazenar entrada rápida
      inputTimeout: null, // Temporizador para detectar final da entrada
      inputStartTime: null, // Tempo do início da entrada
    };
  },
  methods: {
    handlePaste(event) {
      if (!this.pasteAllowed) {
        event.preventDefault();
        this.$swal({
          icon: 'warning',
          title: 'Colagem Bloqueada',
          text: 'A colagem está desativada. Utilize o leitor de código de barras.',
        });
      }
    },
    handleInput(event) {
      // Captura o valor atual do campo
      const currentInput = event.target.value;

      // Validação imediata: Se o comprimento do input for >= 8, processa diretamente
      if (currentInput.length >= 7) {
        this.ean = currentInput;
        console.log('Leitor de código de barras detectado:', this.ean);
        this.processEAN(); // Processa o código de barras
        clearTimeout(this.inputTimeout); // Cancela qualquer validação futura
        this.inputStartTime = null; // Reseta o timer
        return;
      }

      // Inicia o tempo quando o usuário começa a inserir dados
      if (!this.inputStartTime) {
        this.inputStartTime = new Date().getTime();
      }

      // Permite o usuário adicionar "0" manualmente
      if (currentInput === '0' || currentInput.endsWith('0')) {
        this.ean = currentInput; // Atualiza o valor normalmente
        console.log('Zero adicionado manualmente:', this.ean);
        return;
      }

      // Limpa o temporizador anterior
      clearTimeout(this.inputTimeout);

      // Adiciona um pequeno atraso para detectar o final da entrada
      this.inputTimeout = setTimeout(() => {
        const inputEndTime = new Date().getTime();
        const totalTime = inputEndTime - this.inputStartTime;

        // Valida se a entrada foi rápida (ex: leitor de código de barras)
        if (totalTime < 500 || this.pasteAllowed) {
          console.log('Entrada válida:', this.ean);
          this.processEAN(); // Processa o valor final do código
        } else {
          console.log('Entrada manual detectada, bloqueando...');
          this.ean = ''; // Reseta o campo
          this.$swal({
            icon: 'warning',
            title: 'Entrada Manual Bloqueada',
            text: 'Utilize o leitor de código de barras.',
          });
        }

        // Reseta o tempo de início
        this.inputStartTime = null;
      }, 200); // Atraso de 200ms para aguardar a pausa
    },

    processEAN() {
      // Função para processar o valor do EAN
      console.log('Código EAN recebido:', this.ean);
      // Adicione aqui a lógica para verificar o EAN ou enviar para o servidor
    },

    // Método para atualizar o valor do EAN
    updateEAN(event) {
      this.ean = event.target.value;
    },
    openAcceptModal() {
      this.$refs.acceptModal.show();
    },
    allowPaste() {
      if (this.acceptInput.trim().toLowerCase() === 's') {
        this.pasteAllowed = true;
        this.acceptInput = '';
        this.$refs.acceptModal.hide();
        this.$swal({
          icon: 'success',
          title: 'Desbloqueado',
          text: 'Agora você pode colar ou introduzir manualmente o EAN.',
        });
      }
    },
    handleAcceptKeyPress(event) {
      if (event.key === 'Enter') {
        this.allowPaste(); // Chama a função que desbloqueia a colagem
      }
    },
    async openProdPage() {
      window.open(
        `https://${window.location.host}/product/${this.idProduct}`,
        '_blank'
      );
      this.$refs['modal_product'].hide();
    },
    /* OBTER TODOS OS DADOS DOS PRODUTOS DAS ENCOMENDAS */
    async createArrayForArticles() {
      for (let i = 0; i < this.articles.length; i++) {
        console.log(this.articles[i].quantity);
        for (let j = 0; j < this.articles[i].quantity; j++) {
          console.log('ENTROU');
          this.arrayArticles.push(this.articles[i].sku);
        }
      }
      console.log(this.arrayArticles);
      this.addProductsToArray();
    },
    /* REGISTAR PRODUTO */
    async registeProduct() {
      console.log('REGISTO');

      if (!this.ean) {
        console.error('Nenhum EAN fornecido.');
        return;
      }

      console.log('EAN:', this.ean);
      console.log('ARTIGOS:', this.articles);

      // Encontrar produto pelo EAN
      const findEAN = this.articles.find(
        (product) =>
          product.all_ean && product.all_ean.some((ean) => ean === this.ean)
      );

      // Verificar se o produto pelo EAN existe
      let sku = [];
      if (findEAN) {
        sku = findEAN.all_sku;
        console.log('Produto encontrado pelo EAN:', findEAN);
      } else {
        console.log('Nenhum produto encontrado com o EAN fornecido.');
      }

      // Encontrar produto pelo SKU
      const findSKU = this.articles.find(
        (product) =>
          product.all_sku && product.all_sku.some((sku) => sku === this.ean)
      );

      if (findSKU) {
        console.log('Produto encontrado pelo SKU:', findSKU);
        sku = findSKU.all_sku;
      } else {
        console.log('Nenhum produto encontrado com os SKUs fornecidos.');
      }

      // Verificar se o produto faz parte da encomenda
      const productIndex = this.arrayArticles.findIndex((product) =>
        sku.some((s) => s === product)
      );

      console.log('Índice do produto na encomenda:', productIndex);

      if (productIndex === -1) {
        try {
          // Pesquisar produto na API
          await this.$store.dispatch('searchProductsEanSku', {
            search: this.ean,
          });

          const prod_found = this.getProductsSkuEan;
          if (!prod_found || !prod_found[0]) {
            throw new Error('Produto não encontrado na API.');
          }

          console.log('Produto encontrado na API:', prod_found);
          const verificationCode = Math.floor(
            100 + Math.random() * 900
          ).toString();

          await this.$swal({
            icon: 'error',
            title:
              '<span style="color: white; font-size: 2.5rem; font-weight: bold;">Produto não faz parte da encomenda</span>',
            html: `
          <div style="text-align: center; color: white; font-size: 1.5rem;">
            <img src="${prod_found[0].img}" alt="Product Image" style="max-width: 300px; height: auto; margin: 20px 0;">
            <p><strong>SKU:</strong> ${prod_found[0].sku[0]}</p>
            <p><strong>EAN:</strong> ${prod_found[0].ean[0]}</p>
            <p><strong>Nome:</strong> ${prod_found[0].name}</p>
            <p>Digite o seguinte código para confirmar: <strong>${verificationCode}</strong></p>
            <input type="text" id="confirmInput" placeholder="Digite o código"
              style="margin-top: 20px; font-size: 1.2rem; text-align: center; background-color: white; color: black; border: 2px solid #d33; border-radius: 5px;">
          </div>
        `,
            showConfirmButton: true,
            confirmButtonText:
              '<span style="color: white; font-size: 1.2rem;">Confirmar</span>',
            confirmButtonColor: '#b71c1c',
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: () => {
              document.querySelector('.swal2-popup').style.backgroundColor =
                'red';
              const inputElement = document.getElementById('confirmInput');
              inputElement.addEventListener('keydown', (event) => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                  document.querySelector('.swal2-confirm').click();
                }
              });
            },
            preConfirm: () => {
              const inputValue = document.getElementById('confirmInput').value;
              if (inputValue !== verificationCode) {
                this.$swal.showValidationMessage(
                  `O código deve ser exatamente: ${verificationCode}`
                );
              }
              return inputValue;
            },
          });

          // Resetar valores
          this.img = 'https://www.svgrepo.com/show/97910/image.svg';
          this.skuInfo = '';
          this.eanInfo = '';
          this.nameInfo = '';
        } catch (err) {
          console.error('Erro ao buscar produto:', err);
        }
      } else {
        // Produto faz parte da encomenda
        console.log('Produto encontrado na encomenda.');

        const filterProduct = this.articles.find((product) =>
          product.all_sku.some((s) => s === this.arrayArticles[productIndex])
        );

        if (filterProduct) {
          console.log('Produto:', filterProduct);

          if (filterProduct.validity) {
            const currentDate = new Date();
            const sixMonthsFromNow = new Date(currentDate);
            sixMonthsFromNow.setMonth(sixMonthsFromNow.getMonth() + 6);

            const nineMonthsFromNow = new Date(currentDate);
            nineMonthsFromNow.setMonth(nineMonthsFromNow.getMonth() + 9);

            const validityDate = new Date(filterProduct.validity);

            if (validityDate < sixMonthsFromNow) {
              console.log('Validade inferior a 6 meses:', validityDate);
              await this.$swal({
                title: `<span style="color: red;">O produto com SKU: ${filterProduct.all_sku[0]} tem menos de 6 meses de validade!</span>`,
                icon: 'error',
                showCancelButton: true,
                confirmButtonText: 'Sim, continuar!',
                cancelButtonText: 'Cancelar',
              });
            } else if (validityDate < nineMonthsFromNow) {
              console.log('Validade inferior a 9 meses:', validityDate);
              await this.$swal({
                title: `<span style="color: red;">O produto com SKU: ${filterProduct.all_sku[0]} tem menos de 9 meses de validade!</span>`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Sim, continuar!',
                cancelButtonText: 'Cancelar',
              });
            } else {
              console.log('Produto com validade suficiente.');
            }
          } else {
            console.log('Produto sem validade definida.');
          }

          // Verificar notas finais
          if (
            filterProduct.note_finish &&
            filterProduct.note_finish !== 'null' &&
            filterProduct.note_finish.trim() !== ''
          ) {
            console.log('Nota final encontrada:', filterProduct.note_finish);
            await this.$swal({
              title: `O produto com SKU: ${filterProduct.all_sku[0]} tem a seguinte nota: ${filterProduct.note_finish}`,
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Sim, continuar!',
              cancelButtonText: 'Cancelar',
            });
          }

          // Atualizar estado do produto e definir propriedades
          const ind = this.articles.findIndex((product) =>
            product.all_sku.includes(this.arrayArticles[productIndex])
          );

          if (ind !== -1) {
            this.arrayArticles.splice(productIndex, 1);
            this.addProductsToArray();
            this.articles[ind].show = true;

            // Atribuir propriedades ao produto encontrado
            this.img = filterProduct.img;
            this.skuInfo = filterProduct.all_sku[0];
            this.eanInfo = filterProduct.all_ean[0];
            this.nameInfo = filterProduct.name;

            console.log(
              'Estado atualizado para o produto:',
              this.articles[ind]
            );
          }
        } else {
          console.error('Erro ao buscar detalhes do produto.');
        }
      }

      // Limpar EAN após execução
      this.ean = '';
    },
    /* ADICIONAR OS PRODUTOS A UM ARRAY */
    async addProductsToArray() {
      /* FILTRAR PRODUTOS PELO Nº DA ENCOMENDA */
      for (let i = 0; i < this.articles.length; i++) {
        console.log(this.articles[i].quantity);
        /* OBTER DADOS DO PRODUTO */
        let search = this.arrayArticles.filter(
          (product) => product == this.articles[i].sku
        );
        /* OBTER POSIÇÂO DO PRODUTO */
        let index = this.produtsRegisted.findIndex(
          (product) => product.sku == this.articles[i].sku
        );
        /* CASO NÃO ENCONTRE ADICIONAMOS ESTE PRODUTO AO ARRAY DOS PRODUTOS REGISTADOS */
        if (index == -1) {
          this.produtsRegisted.push({
            sku: this.articles[i].sku,
            registed: parseInt(this.articles[i].quantity - search.length),
            total: this.articles[i].quantity,
          });
        } else {
          this.produtsRegisted[index].registed = parseInt(
            this.articles[i].quantity - search.length
          );
        }
        /* } */
      }
    },
    async openGoogle() {
      window.open(
        `https://www.google.com/maps/search/?api=1&basemap=satellite&query=${this.order[0].adress1.replace(
          /\s*$/,
          ''
        )}, ${this.order[0].city}, ${this.order[0].state}, ${
          this.order[0].postcode
        }, ${this.order[0].country}`,
        '_blank'
      );
    },
    /* ESCOLHER DISTRIBUIDORA */
    async choiceCompany() {
      /* this.loading = true; */
      console.log(this.order);
      /* try {
        console.log(this.order[0].id_invoice);
        if (this.order[0].id_invoice == "") {
          await this.$store.dispatch("fetchInvoices", {
            order: this.orderId,
          });
          setTimeout(this.next, 5000);
        } else { */
      this.$parent.getOrder(true);
      this.$root.$emit('shippingCompany');
      this.$parent.choiceCompany();
      /* }
      } catch (err) {
        this.$swal({
          toast: true,
          position: "top-end",
          icon: "error",
          title: err,
          showConfirmButton: false,
          timer: 1500,
        });
      } */
    },
    async next() {
      alert('ESPEROU');
      this.$parent.getOrder(true);
      this.$root.$emit('shippingCompany');
      /* this.$parent.choiceCompany(); */
      this.loading = false;
    },
    /* VERIFICAR A DISTRIBUIDORA DO ENVIO */
    async checkOrderExpedition() {
      try {
        await this.$store.dispatch('fetchOrderExpeditionByID', {
          id: this.id,
        });
        this.expedition = this.getOrderExpeditionByID.data;
        console.log(this.expedition);
        if (this.expedition.length != 0) {
          if (this.expedition[0].shipping_company == 'nacex') {
            this.$parent.getNacexLabel(this.expedition[0].expedition_number);
          } else if (this.expedition[0].shipping_company == 'dhl') {
            console.log(this.expedition[0].tracking_number);
            console.log(this.expedition);
            this.$parent.getCallDHLLabel(
              this.expedition[0].expedition_number,
              this.expedition[0].tracking_number,
              this.expedition[0].xml
            );
          } else if (this.expedition[0].shipping_company == 'ctt expresso') {
            this.$parent.getCallCTTEXPRESSOLabel(
              this.expedition[0].expedition_number
            );
          } else if (this.expedition[0].shipping_company == 'ctt') {
            this.$parent.callCTTLabel(
              this.expedition[0].expedition_number,
              this.expedition[0].tracking_number
            );
          } else if (this.expedition[0].shipping_company == 'fedex') {
            this.$parent.getFedExLabbel(
              this.expedition[0].expedition_number,
              this.expedition[0].tracking_number
            );
          }
        }
      } catch (err) {
        alert(err);
      }
    },
    async getEAN() {
      console.log('Entrou Atualizar EAN');
      try {
        try {
          await this.$store.dispatch('fetchProductsEAN');
          this.eans = this.getProductsEAN;
          console.log('TODOS EANS: ', this.eans);
          /* VERIFICA QUANTAS LINHAS TERÁ A TABELA */
        } catch (err) {
          alert(err);
        } finally {
          this.loading = false;
        }
      } catch (err) {
        alert(err);
      }
    },
    async passProductData(data) {
      // const filterProduct = this.allProducts.filter((product) => product.sku.includes(data.sku));
      this.eanProduct = data.ean;
      this.skuProduct = data.all_sku[0];
      this.hsProduct = data.hscode;
      this.warehouseProduct = data.note_warehouse;
      this.notesProduct = data.note_finish;
      this.weightProduct = data.weight;
      this.imgProduct = data.img;
      this.idProduct = data.id_backoffice;
      this.stockProduct = data.stock;
      // console.log(filterProduct, data);
    },
  },

  computed: {
    ...mapGetters(['getOrderProducts']),
    ...mapGetters(['getOrderExpeditionByID']),
    ...mapGetters(['getProductsSkuEan']),
    // ...mapGetters(['getProductsEAN']),
  },
  mounted() {
    // this.$root.$on('component1', () => {
    //   // your code goes here
    //   this.getProductsFromOders();
    // });
  },
  async created() {
    // await this.getProductsFromOders();
    await this.createArrayForArticles();
    await this.checkOrderExpedition();
    // await this.allCountries();
    // await this.openValidationAddress();
    // await this.getEAN();
  },
};
</script>
<style scoped>
.card {
  padding: 25px;
  background-color: rgba(255, 147, 147, 0.733);
  margin: 50px;
  border: 1px solid #dddd;
  border-radius: 18px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
h3 {
  color: white;
}
p {
  text-align: center;
}
.completed {
  color: green;
  font-weight: bold;
}
form {
  margin: 10px;
}
label {
  text-align: right;
  color: white;
  font-weight: bold;
}
hr {
  background-color: white;
  height: 2px;
  border: 0;
}
#eanLabel {
  background-color: rgb(74, 106, 192);
  border-radius: 6px;
  color: white;
}
.google {
  text-align: center;
}
.google button {
  background-color: none;
}
#modal-address label {
  background-color: white;
  color: black;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
button {
  background-color: rgb(104, 155, 75);
  border: none;
}
button:hover {
  background-color: rgba(104, 155, 75, 0.788);
  border: none;
  box-shadow: none;
}
button:focus {
  background-color: rgb(104, 155, 75);
  border: none;
  box-shadow: none;
}
i {
  text-align: center;
  color: grey;
  opacity: 0.6;
}
img {
  max-width: 100%;
  border-radius: 18px;
}
.card {
  padding: 25px;
  background-color: rgba(255, 147, 147, 0.733);
  margin: 10px;
  border: 1px solid #dddd;
  border-radius: 18px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
#subtitle {
  color: white;
  font-weight: bold;
}
#openArticle {
  color: rgb(0, 98, 255);
}
#openArticle:hover {
  text-decoration: underline;
}
.box img {
  width: 100%;
  height: 200px;
}
.box1 img {
  object-fit: contain;
}
.box1 {
  border: 3px solid #ff9393;
  border-radius: 5px;
}
.infoProduct {
  margin-top: 3%;
}
.registerInput {
  margin-top: 7%;
}
.input-group {
  display: flex;
  align-items: center;
}

.unlock-icon {
  font-size: 16px;
  color: black; /* Ícone preto */
  cursor: pointer;
  transition: transform 0.2s ease, color 0.2s ease;
}

.unlock-icon:hover {
  color: #333; /* Preto mais intenso no hover */
  transform: scale(1.2); /* Aumenta o ícone no hover */
}

.last-registered {
  font-size: 14px;
  color: #6c757d; /* Cinza suave */
}

#eanLabel {
  color: #495057;
}

input.form-control {
  border: 1px solid #ced4da;
  box-shadow: none;
  font-size: 14px;
}

input.form-control:focus {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.btn-primary {
  background-color: #007bff;
  border: none;
}

.btn-primary:hover {
  background-color: #0056b3;
}
</style>
