<template>
  <div class="order">
    <span id="loading" v-if="this.loading == true">
      <Loading />
    </span>
    <a
      ><router-link to="/orders"
        ><b-icon-arrow-left-circle /> Voltar</router-link
      ></a
    >
    <div v-if="this.isPicking == true" v-bind:style="{ display: pickingList }">
      <Picking
        :orderId="this.id"
        :articles="this.articles"
        :order="this.order[0]"
        :expeditionCreated="this.expeditionCreated"
        :amostraTotal="Number(this.order[0].total)"
        :limitTax="Number(this.limitTax.value)"
        :junt_id="this.junt_id"
        :filteredOrders="this.filteredOrders"
      />
    </div>
    <div
      class="products"
      v-if="this.isOrderCard == true"
      v-bind:style="{ display: productsList }"
    >
      <OrderCard
        :orderId="this.id"
        :articles="this.articles"
        :order="this.order"
      />
    </div>
    <div
      class="shippingCompany"
      v-if="this.isShippingCompany == true"
      v-bind:style="{ display: shippingCompany }"
    >
      <ShippingCompany
        @getShipData="updateShipping"
        :orderId="this.id"
        :order="this.order"
        :shippingCompany="this.order[0].shipping_company"
        :shippingTranslate="this.order[0].ship_translate"
        :totalPrice="this.amostras.total_eur"
        :shipping_selection="this.order[0].company_select"
        :cs_choose="this.order[0].cs_choose"
        :weight="this.weight"
        :height="this.height"
        :width="this.width"
        :length1="this.length1"
        :price_eur="this.amostras.total_eur"
      />
    </div>
    <div
      v-if="this.isShippingData == true"
      v-bind:style="{ display: nacexData }"
    >
      <ShippingData
        :orderId="this.id"
        :articles="this.articles"
        :order="this.order"
      />
    </div>
    <div
      v-if="this.isNacexLabel == true"
      v-bind:style="{ display: nacexLabel }"
    >
      <NacexLabel :orderId="this.id" :expedition="this.expedition" />
    </div>
    <div v-if="this.isDHLForm == true" v-bind:style="{ display: dhlData }">
      <DHLForm
        :orderId="this.id"
        :articles="this.articles"
        :order="this.order"
        :comunitary="this.comunitary"
        :amostras="this.amostras.exchange"
        :weight="this.weight"
        :height1="this.height"
        :width1="this.width"
        :length1="this.length1"
        :selectedMaterials1="this.selectedMaterials"
      />
    </div>
    <div v-if="this.isDHLLabel == true" v-bind:style="{ display: dhlLabel }">
      <DHLLabel
        :orderId="this.id"
        :expedition="this.expedition"
        :articles="this.articles"
        :tracking="this.tracking"
        :order="this.order"
        :xml="this.xml"
        :price_eur="this.amostras.total_eur"
      />
    </div>
    <div v-if="this.isCTTForm == true" v-bind:style="{ display: cttForm }">
      <CTTForm
        :orderId="this.id"
        :articles="this.articles"
        :order="this.order"
        :comunitary="this.comunitary"
        :weight="this.weight"
        :cttAzul="this.ctt_azul"
        :selectedMaterials1="this.selectedMaterials"
      />
    </div>
    <div v-if="this.isCTTLabel == true" v-bind:style="{ display: cttLabel }">
      <CTTLabel
        :orderId="this.id"
        :expedition="this.expedition"
        :articles="this.articles"
        :tracking="this.tracking"
        :order="this.order"
        :price_eur="this.amostras.total_eur"
      />
    </div>
    <div
      v-if="this.isCTTEXPRESSOForm == true"
      v-bind:style="{ display: cttExpressoForm }"
    >
      <CTTEXPRESSOForm
        :orderId="this.id"
        :articles="this.articles"
        :order="this.order"
        :comunitary="this.comunitary"
        :weight="this.weight"
        :height1="this.height"
        :width1="this.width"
        :length1="this.length1"
        :selectedMaterials1="this.selectedMaterials"
      />
    </div>
    <div
      v-if="this.isCTTEXPRESSOLabel == true"
      v-bind:style="{ display: cttExpressoLabel }"
    >
      <CTTEXPRESSOLabel
        :orderId="this.id"
        :expedition="this.expedition"
        :articles="this.articles"
        :order="this.order"
        :price_eur="this.amostras.total_eur"
      />
    </div>
    <div v-if="this.isFedExForm == true" v-bind:style="{ display: fedexForm }">
      <FedExForm
        :orderId="this.id"
        :articles="this.articles"
        :order="this.order"
        :comunitary="this.comunitary"
        :weight="this.weight"
        :selectedMaterials1="this.selectedMaterials"
      />
    </div>
    <div
      v-if="this.isFedExLabel == true"
      v-bind:style="{ display: fedexLabel }"
    >
      <FedExLabel
        :orderId="this.id"
        :expedition="this.expedition"
        :label="this.label"
        :tracking="this.tracking"
        :order="this.order"
        :articles="this.articles"
        :price_eur="this.amostras.total_eur"
      />
    </div>
    <div v-if="this.isDPDForm == true" v-bind:style="{ display: dpdForm }">
      <DPDForm
        :orderId="this.id"
        :articles="this.articles"
        :order="this.order"
        :comunitary="this.comunitary"
        :weight="this.weight"
        :selectedMaterials1="this.selectedMaterials"
      />
    </div>
    <div v-if="this.isDPDLabel == true" v-bind:style="{ display: dpdLabel }">
      <DPDLabel
        :orderId="this.id"
        :expedition="this.expedition"
        :label="this.label"
        :tracking="this.tracking"
        :order="this.order"
        :articles="this.articles"
        :price_eur="this.amostras.total_eur"
      />
    </div>
    <div
      v-if="this.isNoExpedition == true"
      v-bind:style="{ display: noExpedition }"
    >
      <h3>Sem Expedição</h3>
      <NoExpedition
        :orderId="this.id"
        :expedition="this.expedition"
        :company="this.company"
        :order="this.order"
        :articles="this.articles"
      />
    </div>
    <div
      v-if="this.isNoShippingCompany == true"
      v-bind:style="{ display: noShippingCompany }"
    >
      <NoShippingCompany :orderId="this.id" :order="this.order" />
    </div>
    <div class="card">
      <div class="row">
        <div
          v-if="this.isPicking == true || this.isOrderCard == true"
          class="col"
        >
          <h5>Encomendas mesmo cliente</h5>
          <div v-if="junt_id.length == 0">
            <p>Não existem mais encomendas deste cliente</p>
          </div>
          <div v-else>
            <span
              v-for="order in junt_id"
              :key="order"
              id="openArticle"
              v-b-modal.modal-encomenda
              @click="passOrderData(order)"
              >[{{ order }}]
            </span>
          </div>
        </div>
        <div class="col">
          <h5>Códigos Promo.</h5>
          <p v-if="codigo_order.code == 'Sem código'">
            <b>{{ codigo_order.code }}</b>
          </p>
          <p v-else style="background-color: red">
            <b>{{ codigo_order.code }}</b>
          </p>
        </div>
        <div class="col">
          <h5>Amostras</h5>
          <p>
            <b>{{ amostras.amostra }}</b>
          </p>
        </div>
        <div class="col">
          <span
            ><b>Total:</b> {{ amostras.total }} {{ amostras.currency }}</span
          >
          <br />
          <span><b>Conversão:</b> {{ amostras.total_eur }} €</span>
        </div>
        <div class="col">
          <h5>Limite Taxação</h5>
          <p
            v-if="Number(amostras.total) > Number(limitTax.value)"
            style="color: red"
          >
            <b>A encomenda passa o limite de taxação!</b>
          </p>
          <p><b>Limite:</b> {{ limitTax.value }} {{ limitTax.currency }}</p>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col">
          <h5>Nossos comentários</h5>
          <div class="row" id="comments">
            <textarea
              class="form-control col-sm-5"
              rows="2"
              v-model="note"
            ></textarea>
            <button class="btn btn-primary col-sm-2" @click="addNote()">
              Enviar
            </button>
          </div>
          <br />
          <div v-for="note in notes.slice().reverse()" :key="note">
            <div style="font-size: large">
              <p style="color: white; background-color: red">
                <b>{{ note.user }}</b
                >➡️{{ note.comment }}
              </p>
            </div>
          </div>
        </div>
        <div style="font-size: large" class="col">
          <h5>Comentários Cliente</h5>
          <p
            style="color: white; background-color: red"
            v-if="
              this.order[0].customer_note != undefined &&
              this.order[0].customer_note != null &&
              this.order[0].customer_note != ''
            "
          >
            {{ this.order[0].customer_note }}
          </p>
          <p v-else>Sem comentários</p>
        </div>
      </div>
      <hr />
      <div style="align-content: center">
        <button class="btn btn-primary col-sm-2" @click="openWoo()">
          WooCommerce
        </button>
        <button class="btn btn-success col-sm-2" @click="openGoogle()">
          Google
        </button>
      </div>
    </div>
    <!-- MODAIS -->
    <b-modal
      ref="modal_product"
      id="modal-encomenda"
      :title="'Order ' + this.modal_id"
      size="lg"
      hide-footer
    >
      <div class="card">
        <h5>Artigos</h5>
        <hr />
        <b-container>
          <b-row align-v="center">
            <b-col>
              <p
                style="font-size: medium"
                v-for="article in articles_modal"
                :key="article.sku"
              >
                {{ article.quantity }} x {{ article.sku }} -
                <a
                  style="font-size: medium; margin: 0px"
                  id="openArticle"
                  v-b-modal.modal-juntar
                  @click="passProductData(article)"
                  >{{ article.name }}
                </a>
              </p>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </b-modal>
    <b-modal
      ref="modal_product"
      id="modal-juntar"
      :title="'Produto ' + this.skuProduct"
      size="lg"
      hide-footer
    >
      <div class="card">
        <b-container>
          <b-row align-v="center"
            ><b-col class="left">
              <!-- IMAGEM DO PRODUTO -->
              <img :src="imgProduct" />
            </b-col>
            <b-col>
              <p><span id="subtitle">STOCK: </span> {{ stockProduct }}</p>
              <p><span id="subtitle">EAN:</span> {{ eanProduct }}</p>
              <p><span id="subtitle">SKU:</span> {{ skuProduct }}</p>
              <p><span id="subtitle">HS Code:</span> {{ hsProduct }}</p>
              <p>
                <span id="subtitle">Notas Arm.:</span> {{ warehouseProduct }}
              </p>
              <p><span id="subtitle">Notas:</span> {{ notesProduct }}</p>
              <p><span id="subtitle">Peso:</span> {{ weightProduct }} (g)</p>
            </b-col></b-row
          >
        </b-container>
        <button class="btn btn-info" @click="openProdPage()">
          Editar Produto
        </button>
      </div>
    </b-modal>
    <b-modal
      ref="modal_address"
      id="modal-address"
      :title="'Validar Morada'"
      size="lg"
      hide-footer
    >
      <div class="form-group">
        <label for="addressInput">Morada:</label>
        <input
          type="text"
          class="form-control"
          id="addressInput"
          v-model="addressUpdate"
        />
      </div>
      <div class="form-group">
        <label for="cityInput">Cidade:</label>
        <input
          type="text"
          class="form-control"
          id="cityInput"
          v-model="cityUpdate"
        />
      </div>
      <div class="form-group">
        <label for="postalCodeInput">Código Postal:</label>
        <input
          type="text"
          class="form-control"
          id="postalCodeInput"
          v-model="postalCodeUpdate"
        />
      </div>
      <div class="form-group">
        <label for="stateInput">Estado:</label>
        <input
          type="text"
          class="form-control"
          id="stateInput"
          v-model="stateUpdate"
        />
      </div>
      <div class="form-group">
        <label for="exampleFormControlSelect1">País</label>
        <select
          class="form-control"
          id="exampleFormControlSelect1"
          v-model="countryUpdate"
        >
          <option
            v-for="country in allCountries"
            :key="country.code"
            :value="country.code"
          >
            {{ country.code }} ({{ country.name }})
          </option>
        </select>
        <div class="google">
          <button class="btn btn-success" @click="openGoogle()">
            Abrir Google Maps
          </button>
        </div>
        <div class="google">
          <button
            type="submit"
            class="btn btn-primary"
            @click="approveValidationAddress()"
          >
            Validar Morada
          </button>
          <button class="btn btn-warning" @click="sendEmailToApproveAddress()">
            Enviar email ao cliente
          </button>
        </div>
      </div>
    </b-modal>
    <!-- <button @click="ctt()">CTT</button> -->
  </div>
</template>
<script>
import OrderCard from '@/components/OrderCard.vue';
import ShippingCompany from '@/components/ShippingCompany.vue';
import Picking from '@/components/Picking.vue';
import ShippingData from '@/components/ShippingData.vue';
import NacexLabel from '@/components/NacexLabel.vue';
import DHLForm from '@/components/DHLForm.vue';
import DHLLabel from '@/components/DHLLabel';
import CTTForm from '@/components/CTTForm';
import CTTEXPRESSOForm from '@/components/CTTEXPRESSOForm';
import CTTEXPRESSOLabel from '@/components/CTTEXPRESSOLabel1';
import CTTLabel from '@/components/CTTLabel';
import Loading from '@/components/Loading.vue';
import NoExpedition from '@/components/NoExpeditionInformation';
import { mapGetters } from 'vuex';
import FedExForm from '@/components/FedExForm.vue';
import FedExLabel from '../components/FedExLabel.vue';
import DPDForm from '../components/DPDForm.vue';
import DPDLabel from '../components/DPDLabel.vue';
import NoShippingCompany from '@/components/NoShippingCompany';
export default {
  components: {
    OrderCard,
    ShippingCompany,
    Picking,
    ShippingData,
    NacexLabel,
    DHLForm,
    DHLLabel,
    CTTForm,
    CTTEXPRESSOForm,
    CTTEXPRESSOLabel,
    CTTLabel,
    Loading,
    NoExpedition,
    FedExForm,
    FedExLabel,
    DPDForm,
    DPDLabel,
    NoShippingCompany,
  },

  data: function () {
    return {
      order: [],
      articles: [],
      id: '',
      loading: false,
      countries: [],
      productsList: 'none',
      shippingCompany: 'none',
      pickingList: 'none',
      nacexData: 'none',
      nacexLabel: 'none',
      expedition: '',
      tracking: '',
      dhlData: 'none',
      dhlLabel: 'none',
      comunitary: true,
      cttForm: 'none',
      xml: '',
      cttExpressoForm: 'none',
      cttExpressoLabel: 'none',
      fedexForm: 'none',
      fedexLabel: 'none',
      dpdForm: 'none',
      dpdLabel: 'none',
      cttLabel: 'none',
      noExpedition: 'none',
      noShippingCompany: 'none',
      company: '',
      expeditionCreated: false,
      label: '',
      allProducts: [],
      notes: [],
      note: '',
      orderProducts: [],
      isPicking: false,
      isOrderCard: false,
      isShippingCompany: false,
      isShippingData: false,
      isNacexLabel: false,
      isDHLForm: false,
      isDHLLabel: false,
      isCTTForm: false,
      isCTTLabel: false,
      isCTTEXPRESSOForm: false,
      isCTTEXPRESSOLabel: false,
      isFedExForm: false,
      isFedExLabel: false,
      isDPDForm: false,
      isDPDLabel: false,
      isNoExpedition: false,
      isNoShippingCompany: false,
      url_woo: '',
      filteredOrders: [],
      emailSearch: '',
      junt_id: [],
      modal_id: '',
      articles_modal: [],
      eanProduct: '',
      skuProduct: '',
      hsProduct: '',
      warehouseProduct: '',
      notesProduct: '',
      weightProduct: '',
      imgProduct: '',
      idProduct: '',
      stockProduct: '',
      amostras: '',
      codigo_order: '',
      limitTax: '',
      transactions: [],
      weight: '',
      ctt_azul: '',
      height: '',
      width: '',
      length1: '',
      selectedMaterials: [],
      addressUpdate: '',
      cityUpdate: '',
      postalCodeUpdate: '',
      stateUpdate: '',
      idOrderUpdate: '',
      countryUpdate: '',
      emailUpdate: '',
      firstName: '',
      secondName: '',
      phone: '',
      allCountries: [],
    };
  },

  // TODO: comentar getAmostras_fun() e getTaxacao() ao testar!
  async created() {
    this.loading = true;
    await this.getOrder(false);
    await this.filterOrders();
    await this.getCodigoOrder();
    await this.getAmostras_fun();
    await this.getTaxacao();
    await this.AllCountries();
    await this.openValidationAddress();
    /* this.checkOrderExpedition(); */
    this.loading = false;
  },

  methods: {
    async updateShipping(data) {
      this.weight = data.weight;
      this.height = data.height;
      this.width = data.width;
      this.length1 = data.length1;
      this.selectedMaterials = data.selectedMaterials;
    },
    async openProdPage() {
      window.open(
        `https://${window.location.host}/product/${this.idProduct}`,
        '_blank'
      );
      this.$refs['modal_product'].hide();
    },

    async passProductData(data) {
      // const filterProduct = this.allProducts.filter((product) => product.sku.includes(data.sku));
      this.eanProduct = data.ean;
      this.skuProduct = data.sku;
      this.hsProduct = data.hscode;
      this.warehouseProduct = data.note_warehouse;
      this.notesProduct = data.note_finish;
      this.weightProduct = data.weight;
      this.imgProduct = data.img;
      this.idProduct = data.id_backoffice;
      this.stockProduct = data.stock;
      // console.log(filterProduct, data);
    },
    async passOrderData(id) {
      const filterOrder = this.filteredOrders.filter((order) => order.id == id);
      console.log(filterOrder);
      this.modal_id = filterOrder[0].id;
      this.articles_modal = JSON.parse(filterOrder[0].articles);
    },
    async openWoo() {
      window.open(this.url_woo);
    },
    async openGoogle() {
      window.open(
        `https://www.google.com/maps/search/?api=1&basemap=satellite&query=${this.order[0].adress1},${this.order[0].city},${this.order[0].state},${this.order[0].postcode},${this.order[0].country}`,
        '_blank'
      );
    },
    async filterOrders() {
      if (this.emailSearch == '') {
        console.log('Não pesquisa');
        this.filteredOrders = [];
      } else {
        try {
          await this.$store.dispatch('fetchOrdersFilterJunt', {
            email: this.emailSearch,
          });
          this.filteredOrders = this.getFilterOrders;
          for (let index = 0; index < this.filteredOrders.length; index++) {
            const element = this.filteredOrders[index];
            if (element.id != this.id) {
              this.junt_id.push(element.id);
            }
          }
        } catch (err) {
          console.log(err);
          alert(err);
        }
      }
    },
    async getOrder(company = false) {
      try {
        this.id = this.$route.params.id;
        await this.$store.dispatch('fetchOrderById', {
          id: this.id,
        });
        await this.$store.dispatch('getPickingByID', {
          id: this.id,
        });
        this.url_woo = `https://www.idivia.com/wp-admin/post.php?post=${this.id}&action=edit`;
        if (company) {
          this.choiceCompany();
        } else {
          this.order = this.getOrderId.data;
          this.transactions = this.getPickingTrans;
          console.log('PICKINGTRANS: ', this.transactions);
          console.log('Status ' + this.order[0].status);
          if (
            this.order[0].status == 'completed' ||
            this.order[0].status == 'returned' ||
            this.order[0].status == 'confirmed/returned' ||
            this.order[0].status == 'lost_with_insurance' ||
            this.order[0].status == 'lost_without_insurance' ||
            this.order[0].status == 'compensated'
          ) {
            await this.checkOrderExpedition('completed');
          } else if (
            this.order[0].status == 'processing' ||
            this.order[0].status == 'printing' ||
            this.order[0].status == 'packing'
          ) {
            await this.checkOrderExpedition('processing');
          }
        }
        this.emailSearch = this.order[0].email;
        this.articles = JSON.parse(this.order[0].articles);
        for (const prod_trans of this.transactions) {
          let index = this.articles.findIndex(
            (prod) => prod.sku == prod_trans.sku
          );
          if (index != -1) {
            this.articles[index].ordered = prod_trans.ordered;
            this.articles[index].picked = prod_trans.picked;
            this.articles[index].stock = prod_trans.stock;
            this.articles[index].price = prod_trans.price;
            this.articles[index].price_eur = prod_trans.price_eur;
            this.articles[index].coin = prod_trans.coin;
            this.articles[index].notes = prod_trans.note_finish;
            this.articles[index].show = prod_trans.show;
          }
        }
        console.log('NOTAS', this.order[0].notes);
        if (this.order[0].notes != undefined && this.order[0].notes != null) {
          console.log('ENTREI ONDE NÃO DEVIA');
          this.notes = Array.isArray(this.order[0].notes)
            ? this.order[0].notes
            : [];
        } else {
          console.log('Nao entrei');
          this.notes = [];
        }
        this.getCountry();
      } catch (err) {
        alert(err);
      }
    },
    choiceCompany() {
      this.productsList = 'none';
      this.shippingCompany = 'block';
      this.pickingList = 'none';
      this.nacexData = 'none';
      this.nacexLabel = 'none';
      this.dhlData = 'none';
      this.dhlLabel = 'none';
      this.cttForm = 'none';
      this.cttExpressoForm = 'none';
      this.cttExpressoLabel = 'none';
      this.cttLabel = 'none';
      this.noExpedition = 'none';
      this.fedexForm = 'none';
      this.fedexLabel = 'none';
      this.noShippingCompany = 'none';
      this.isPicking = false;
      this.isOrderCard = false;
      this.isShippingCompany = true;
      this.isShippingData = false;
      this.isNacexLabel = false;
      this.isDHLForm = false;
      this.isDHLLabel = false;
      this.isCTTForm = false;
      this.isCTTLabel = false;
      this.isCTTEXPRESSOForm = false;
      this.isCTTEXPRESSOLabel = false;
      this.isFedExForm = false;
      this.isFedExLabel = false;
      this.isDPDForm = false;
      this.isDPDLabel = false;
      this.isNoExpedition = false;
      this.isNoShippingCompany = false;
    },
    productsOrderList() {
      this.productsList = 'block';
      this.shippingCompany = 'none';
      this.pickingList = 'none';
      this.nacexData = 'none';
      this.nacexLabel = 'none';
      this.dhlData = 'none';
      this.dhlLabel = 'none';
      this.cttForm = 'none';
      this.cttExpressoForm = 'none';
      this.cttExpressoLabel = 'none';
      this.cttLabel = 'none';
      this.noExpedition = 'none';
      this.fedexForm = 'none';
      this.fedexLabel = 'none';
      this.noShippingCompany = 'none';
      this.isPicking = false;
      this.isOrderCard = true;
      this.isShippingCompany = false;
      this.isShippingData = false;
      this.isNacexLabel = false;
      this.isDHLForm = false;
      this.isDHLLabel = false;
      this.isCTTForm = false;
      this.isCTTLabel = false;
      this.isCTTEXPRESSOForm = false;
      this.isCTTEXPRESSOLabel = false;
      this.isFedExForm = false;
      this.isFedExLabel = false;
      this.isDPDForm = false;
      this.isDPDLabel = false;
      this.isNoExpedition = false;
      this.isNoShippingCompany = false;
    },
    pickingListProducts() {
      this.productsList = 'none';
      this.shippingCompany = 'none';
      this.pickingList = 'block';
      this.nacexData = 'none';
      this.nacexLabel = 'none';
      this.dhlData = 'none';
      this.dhlLabel = 'none';
      this.cttForm = 'none';
      this.cttExpressoForm = 'none';
      this.cttExpressoLabel = 'none';
      this.cttLabel = 'none';
      this.noExpedition = 'none';
      this.fedexForm = 'none';
      this.fedexLabel = 'none';
      this.noShippingCompany = 'none';
      this.isPicking = true;
      this.isOrderCard = false;
      this.isShippingCompany = false;
      this.isShippingData = false;
      this.isNacexLabel = false;
      this.isDHLForm = false;
      this.isDHLLabel = false;
      this.isCTTForm = false;
      this.isCTTLabel = false;
      this.isCTTEXPRESSOForm = false;
      this.isCTTEXPRESSOLabel = false;
      this.isFedExForm = false;
      this.isFedExLabel = false;
      this.isDPDForm = false;
      this.isDPDLabel = false;
      this.isNoExpedition = false;
      this.isNoShippingCompany = false;
    },
    callNacex() {
      this.productsList = 'none';
      this.shippingCompany = 'none';
      this.pickingList = 'none';
      this.nacexData = 'block';
      this.nacexLabel = 'none';
      this.dhlData = 'none';
      this.dhlLabel = 'none';
      this.cttForm = 'none';
      this.cttExpressoForm = 'none';
      this.cttExpressoLabel = 'none';
      this.cttLabel = 'none';
      this.noExpedition = 'none';
      this.fedexForm = 'none';
      this.fedexLabel = 'none';
      this.noShippingCompany = 'none';
      this.isPicking = false;
      this.isOrderCard = false;
      this.isShippingCompany = false;
      this.isShippingData = true;
      this.isNacexLabel = false;
      this.isDHLForm = false;
      this.isDHLLabel = false;
      this.isCTTForm = false;
      this.isCTTLabel = false;
      this.isCTTEXPRESSOForm = false;
      this.isCTTEXPRESSOLabel = false;
      this.isFedExForm = false;
      this.isFedExLabel = false;
      this.isDPDForm = false;
      this.isDPDLabel = false;
      this.isNoExpedition = false;
      this.isNoShippingCompany = false;
    },
    getNacexLabel(expedition) {
      this.productsList = 'none';
      this.shippingCompany = 'none';
      this.pickingList = 'none';
      this.nacexData = 'none';
      this.nacexLabel = 'block';
      this.expedition = expedition;
      this.dhlData = 'none';
      this.dhlLabel = 'none';
      this.cttForm = 'none';
      this.cttExpressoForm = 'none';
      this.cttExpressoLabel = 'none';
      this.cttLabel = 'none';
      this.noExpedition = 'none';
      this.fedexForm = 'none';
      this.fedexLabel = 'none';
      this.noShippingCompany = 'none';
      this.isPicking = false;
      this.isOrderCard = false;
      this.isShippingCompany = false;
      this.isShippingData = false;
      this.isNacexLabel = true;
      this.isDHLForm = false;
      this.isDHLLabel = false;
      this.isCTTForm = false;
      this.isCTTLabel = false;
      this.isCTTEXPRESSOForm = false;
      this.isCTTEXPRESSOLabel = false;
      this.isFedExForm = false;
      this.isFedExLabel = false;
      this.isDPDForm = false;
      this.isDPDLabel = false;
      this.isNoExpedition = false;
      this.isNoShippingCompany = false;
    },
    callDHL() {
      this.productsList = 'none';
      this.shippingCompany = 'none';
      this.pickingList = 'none';
      this.nacexData = 'none';
      this.nacexLabel = 'none';
      this.dhlData = 'block';
      this.dhlLabel = 'none';
      this.cttForm = 'none';
      this.cttExpressoForm = 'none';
      this.cttExpressoLabel = 'none';
      this.cttLabel = 'none';
      this.noExpedition = 'none';
      this.fedexForm = 'none';
      this.fedexLabel = 'none';
      this.noShippingCompany = 'none';
      this.isPicking = false;
      this.isOrderCard = false;
      this.isShippingCompany = false;
      this.isShippingData = false;
      this.isNacexLabel = false;
      this.isDHLForm = true;
      this.isDHLLabel = false;
      this.isCTTForm = false;
      this.isCTTLabel = false;
      this.isCTTEXPRESSOForm = false;
      this.isCTTEXPRESSOLabel = false;
      this.isFedExForm = false;
      this.isFedExLabel = false;
      this.isDPDForm = false;
      this.isDPDLabel = false;
      this.isNoExpedition = false;
      this.isNoShippingCompany = false;
    },
    getCallDHLLabel(expedition, tracking_number, xml) {
      this.productsList = 'none';
      this.shippingCompany = 'none';
      this.pickingList = 'none';
      this.nacexData = 'none';
      this.nacexLabel = 'none';
      this.dhlData = 'none';
      this.dhlLabel = 'block';
      this.cttForm = 'none';
      this.expedition = expedition;
      this.tracking = tracking_number;
      this.xml = xml;
      this.cttExpressoLabel = 'none';
      this.cttExpressoForm = 'none';
      this.cttLabel = 'none';
      this.noExpedition = 'none';
      this.fedexForm = 'none';
      this.fedexLabel = 'none';
      this.noShippingCompany = 'none';
      this.isPicking = false;
      this.isOrderCard = false;
      this.isShippingCompany = false;
      this.isShippingData = false;
      this.isNacexLabel = false;
      this.isDHLForm = false;
      this.isDHLLabel = true;
      this.isCTTForm = false;
      this.isCTTLabel = false;
      this.isCTTEXPRESSOForm = false;
      this.isCTTEXPRESSOLabel = false;
      this.isFedExForm = false;
      this.isFedExLabel = false;
      this.isDPDForm = false;
      this.isDPDLabel = false;
      this.isNoExpedition = false;
      this.isNoShippingCompany = false;
    },
    callCTT(azul) {
      this.productsList = 'none';
      this.shippingCompany = 'none';
      this.pickingList = 'none';
      this.nacexData = 'none';
      this.nacexLabel = 'none';
      this.dhlData = 'none';
      this.dhlLabel = 'none';
      this.cttForm = 'block';
      this.cttExpressoForm = 'none';
      this.cttExpressoLabel = 'none';
      this.cttLabel = 'none';
      this.noExpedition = 'none';
      this.fedexForm = 'none';
      this.fedexLabel = 'none';
      this.noShippingCompany = 'none';
      this.isPicking = false;
      this.isOrderCard = false;
      this.isShippingCompany = false;
      this.isShippingData = false;
      this.isNacexLabel = false;
      this.isDHLForm = false;
      this.isDHLLabel = false;
      this.isCTTForm = true;
      this.isCTTLabel = false;
      this.isCTTEXPRESSOForm = false;
      this.isCTTEXPRESSOLabel = false;
      this.isFedExForm = false;
      this.isFedExLabel = false;
      this.isDPDForm = false;
      this.isDPDLabel = false;
      this.isNoExpedition = false;
      this.isNoShippingCompany = false;
      this.ctt_azul = azul;
    },
    callCTTLabel(expedition, tracking_number) {
      this.productsList = 'none';
      this.shippingCompany = 'none';
      this.pickingList = 'none';
      this.nacexData = 'none';
      this.nacexLabel = 'none';
      this.dhlData = 'none';
      this.dhlLabel = 'none';
      this.cttForm = 'none';
      this.cttLabel = 'block';
      this.expedition = expedition;
      this.tracking = tracking_number;
      this.cttExpressoForm = 'none';
      this.cttExpressoLabel = 'none';
      this.noExpedition = 'none';
      this.fedexForm = 'none';
      this.fedexLabel = 'none';
      this.noShippingCompany = 'none';
      this.isPicking = false;
      this.isOrderCard = false;
      this.isShippingCompany = false;
      this.isShippingData = false;
      this.isNacexLabel = false;
      this.isDHLForm = false;
      this.isDHLLabel = false;
      this.isCTTForm = false;
      this.isCTTLabel = true;
      this.isCTTEXPRESSOForm = false;
      this.isCTTEXPRESSOLabel = false;
      this.isFedExForm = false;
      this.isFedExLabel = false;
      this.isDPDForm = false;
      this.isDPDLabel = false;
      this.isNoExpedition = false;
      this.isNoShippingCompany = false;
    },
    callCTTExpresso() {
      this.productsList = 'none';
      this.shippingCompany = 'none';
      this.pickingList = 'none';
      this.nacexData = 'none';
      this.nacexLabel = 'none';
      this.dhlData = 'none';
      this.dhlLabel = 'none';
      this.cttForm = 'none';
      this.cttExpressoForm = 'block';
      this.cttExpressoLabel = 'none';
      this.cttLabel = 'none';
      this.noExpedition = 'none';
      this.fedexForm = 'none';
      this.fedexLabel = 'none';
      this.noShippingCompany = 'none';
      this.isPicking = false;
      this.isOrderCard = false;
      this.isShippingCompany = false;
      this.isShippingData = false;
      this.isNacexLabel = false;
      this.isDHLForm = false;
      this.isDHLLabel = false;
      this.isCTTForm = false;
      this.isCTTLabel = false;
      this.isCTTEXPRESSOForm = true;
      this.isCTTEXPRESSOLabel = false;
      this.isFedExForm = false;
      this.isFedExLabel = false;
      this.isDPDForm = false;
      this.isDPDLabel = false;
      this.isNoExpedition = false;
      this.isNoShippingCompany = false;
    },
    getCallCTTEXPRESSOLabel(expedition) {
      this.productsList = 'none';
      this.shippingCompany = 'none';
      this.pickingList = 'none';
      this.nacexData = 'none';
      this.nacexLabel = 'none';
      this.dhlData = 'none';
      this.dhlLabel = 'none';
      this.cttForm = 'none';
      this.expedition = expedition;
      this.cttExpressoForm = 'none';
      this.cttExpressoLabel = 'block';
      this.cttLabel = 'none';
      this.noExpedition = 'none';
      this.fedexForm = 'none';
      this.fedexLabel = 'none';
      this.noShippingCompany = 'none';
      this.isPicking = false;
      this.isOrderCard = false;
      this.isShippingCompany = false;
      this.isShippingData = false;
      this.isNacexLabel = false;
      this.isDHLForm = false;
      this.isDHLLabel = false;
      this.isCTTForm = false;
      this.isCTTLabel = false;
      this.isCTTEXPRESSOForm = false;
      this.isCTTEXPRESSOLabel = true;
      this.isFedExForm = false;
      this.isFedExLabel = false;
      this.isDPDForm = false;
      this.isDPDLabel = false;
      this.isNoExpedition = false;
      this.isNoShippingCompany = false;
    },
    callFedEx() {
      this.productsList = 'none';
      this.shippingCompany = 'none';
      this.pickingList = 'none';
      this.nacexData = 'none';
      this.nacexLabel = 'none';
      this.dhlData = 'none';
      this.dhlLabel = 'none';
      this.cttForm = 'none';
      this.cttExpressoForm = 'none';
      this.cttExpressoLabel = 'none';
      this.cttLabel = 'none';
      this.noExpedition = 'none';
      this.fedexForm = 'block';
      this.fedexLabel = 'none';
      this.noShippingCompany = 'none';
      this.isPicking = false;
      this.isOrderCard = false;
      this.isShippingCompany = false;
      this.isShippingData = false;
      this.isNacexLabel = false;
      this.isDHLForm = false;
      this.isDHLLabel = false;
      this.isCTTForm = false;
      this.isCTTLabel = false;
      this.isCTTEXPRESSOForm = false;
      this.isCTTEXPRESSOLabel = false;
      this.isFedExForm = true;
      this.isFedExLabel = false;
      this.isDPDForm = false;
      this.isDPDLabel = false;
      this.isNoExpedition = false;
      this.isNoShippingCompany = false;
    },
    getFedExLabel(expedition, label, tracking) {
      this.productsList = 'none';
      this.shippingCompany = 'none';
      this.pickingList = 'none';
      this.nacexData = 'none';
      this.nacexLabel = 'none';
      this.dhlData = 'none';
      this.dhlLabel = 'none';
      this.cttForm = 'none';
      this.expedition = expedition;
      this.tracking = tracking;
      this.label = label;
      this.cttExpressoForm = 'none';
      this.cttExpressoLabel = 'none';
      this.cttLabel = 'none';
      this.noExpedition = 'none';
      this.fedexForm = 'none';
      this.fedexLabel = 'block';
      this.noShippingCompany = 'none';
      this.isPicking = false;
      this.isOrderCard = false;
      this.isShippingCompany = false;
      this.isShippingData = false;
      this.isNacexLabel = false;
      this.isDHLForm = false;
      this.isDHLLabel = false;
      this.isCTTForm = false;
      this.isCTTLabel = false;
      this.isCTTEXPRESSOForm = false;
      this.isCTTEXPRESSOLabel = false;
      this.isFedExForm = false;
      this.isFedExLabel = true;
      this.isDPDForm = false;
      this.isDPDLabel = false;
      this.isNoExpedition = false;
      this.isNoShippingCompany = false;
    },
    callDPD() {
      this.productsList = 'none';
      this.shippingCompany = 'none';
      this.pickingList = 'none';
      this.nacexData = 'none';
      this.nacexLabel = 'none';
      this.dhlData = 'none';
      this.dhlLabel = 'none';
      this.cttForm = 'none';
      this.cttExpressoForm = 'none';
      this.cttExpressoLabel = 'none';
      this.cttLabel = 'none';
      this.noExpedition = 'none';
      this.fedexForm = 'none';
      this.dpdForm = 'block';
      this.dpdLabel = 'none';
      this.fedexLabel = 'none';
      this.noShippingCompany = 'none';
      this.isPicking = false;
      this.isOrderCard = false;
      this.isShippingCompany = false;
      this.isShippingData = false;
      this.isNacexLabel = false;
      this.isDHLForm = false;
      this.isDHLLabel = false;
      this.isCTTForm = false;
      this.isCTTLabel = false;
      this.isCTTEXPRESSOForm = false;
      this.isCTTEXPRESSOLabel = false;
      this.isFedExForm = false;
      this.isFedExLabel = false;
      this.isDPDForm = true;
      this.isDPDLabel = false;
      this.isNoExpedition = false;
      this.isNoShippingCompany = false;
    },
    getDPDLabel(expedition, label, tracking) {
      this.productsList = 'none';
      this.shippingCompany = 'none';
      this.pickingList = 'none';
      this.nacexData = 'none';
      this.nacexLabel = 'none';
      this.dhlData = 'none';
      this.dhlLabel = 'none';
      this.cttForm = 'none';
      this.expedition = expedition;
      this.tracking = tracking;
      this.label = label;
      this.cttExpressoForm = 'none';
      this.cttExpressoLabel = 'none';
      this.cttLabel = 'none';
      this.noExpedition = 'none';
      this.fedexForm = 'none';
      this.fedexLabel = 'none';
      this.dpdForm = 'none';
      this.dpdLabel = 'block';
      this.noShippingCompany = 'none';
      this.isPicking = false;
      this.isOrderCard = false;
      this.isShippingCompany = false;
      this.isShippingData = false;
      this.isNacexLabel = false;
      this.isDHLForm = false;
      this.isDHLLabel = false;
      this.isCTTForm = false;
      this.isCTTLabel = false;
      this.isCTTEXPRESSOForm = false;
      this.isCTTEXPRESSOLabel = false;
      this.isFedExForm = false;
      this.isFedExLabel = false;
      this.isDPDForm = false;
      this.isDPDLabel = true;
      this.isNoExpedition = false;
      this.isNoShippingCompany = false;
    },
    callNoExpedition(expedition, company) {
      this.productsList = 'none';
      this.shippingCompany = 'none';
      this.pickingList = 'none';
      this.nacexData = 'none';
      this.nacexLabel = 'none';
      this.dhlData = 'none';
      this.dhlLabel = 'none';
      this.cttForm = 'none';
      this.expedition = expedition;
      this.company = company;
      this.cttExpressoForm = 'none';
      this.cttExpressoLabel = 'none';
      this.cttLabel = 'none';
      this.noExpedition = 'block';
      this.fedexForm = 'none';
      this.fedexLabel = 'none';
      this.noShippingCompany = 'none';
      this.isPicking = false;
      this.isOrderCard = false;
      this.isShippingCompany = false;
      this.isShippingData = false;
      this.isNacexLabel = false;
      this.isDHLForm = false;
      this.isDHLLabel = false;
      this.isCTTForm = false;
      this.isCTTLabel = false;
      this.isCTTEXPRESSOForm = false;
      this.isCTTEXPRESSOLabel = false;
      this.isFedExForm = false;
      this.isFedExLabel = false;
      this.isDPDForm = false;
      this.isDPDLabel = false;
      this.isNoExpedition = true;
      this.isNoShippingCompany = false;
    },
    callNoShippingCompany() {
      this.productsList = 'none';
      this.shippingCompany = 'none';
      this.pickingList = 'none';
      this.nacexData = 'none';
      this.nacexLabel = 'none';
      this.dhlData = 'none';
      this.dhlLabel = 'none';
      this.cttForm = 'none';
      this.cttExpressoForm = 'none';
      this.cttExpressoLabel = 'none';
      this.cttLabel = 'none';
      this.noExpedition = 'none';
      this.fedexForm = 'none';
      this.fedexLabel = 'none';
      this.noShippingCompany = 'none';
      this.noShippingCompany = 'block';
      this.isPicking = false;
      this.isOrderCard = false;
      this.isShippingCompany = false;
      this.isShippingData = false;
      this.isNacexLabel = false;
      this.isDHLForm = false;
      this.isDHLLabel = false;
      this.isCTTForm = false;
      this.isCTTLabel = false;
      this.isCTTEXPRESSOForm = false;
      this.isCTTEXPRESSOLabel = false;
      this.isFedExForm = false;
      this.isFedExLabel = false;
      this.isDPDForm = false;
      this.isDPDLabel = false;
      this.isNoExpedition = false;
      this.isNoShippingCompany = true;
    },
    async afterCreateInvoice() {
      console.log('DEPOIS');
      await this.getOrder();
      this.productsOrderList();
    },
    async checkOrderExpedition(status) {
      try {
        await this.$store.dispatch('fetchOrderExpeditionByID', {
          id: this.id,
        });
        /* VERIFICAR SE A EXPEDIÇÃO PARA ESTA ENCOMENDA JÁ FOI EFETUADA */
        this.expedition = this.getOrderExpeditionByID.data;
        console.log(this.expedition);
        console.log('STATUUUUUS ', status);
        if (this.expedition.length != 0) {
          /* CASO JÁ TENHA SIDOn CRIADA VAMOS VERIFICAR POR QUAL TRANSPORTADORA */
          this.expeditionCreated = true;
          if (this.expedition[0].shipping_company == 'nacex') {
            this.$parent.getNacexLabel(this.expedition[0].expedition_number);
          } else if (this.expedition[0].shipping_company == 'dhl') {
            console.log('DHL');
            this.getCallDHLLabel(
              this.expedition[0].expedition_number,
              this.expedition[0].tracking_number,
              this.expedition[0].xml
            );
          } else if (this.expedition[0].shipping_company == 'ctt expresso') {
            this.getCallCTTEXPRESSOLabel(this.expedition[0].expedition_number);
          } else if (this.expedition[0].shipping_company == 'ctt') {
            console.log('AQUI');
            console.log(
              this.expedition[0].expedition_number,
              this.expedition[0].tracking_number
            );
            this.callCTTLabel(
              this.expedition[0].expedition_number,
              this.expedition[0].tracking_number
            );
          } else if (this.expedition[0].shipping_company == 'fedex') {
            this.getFedExLabel(
              this.expedition[0].expedition_number,
              this.expedition[0].label,
              this.expedition[0].tracking_number
            );
          } else if (this.expedition[0].shipping_company == 'dpd') {
            this.getDPDLabel(
              this.expedition[0].expedition_number,
              this.expedition[0].label,
              this.expedition[0].tracking_number
            );
          }
        } else if (this.expedition.length == 0 && status == 'completed') {
          console.log('AQUI');
          this.callNoExpedition(
            this.order[0].tracking_id,
            this.order[0].delivery_company
          );
        } else if (this.expedition.length == 0 && status == 'processing') {
          if (
            this.order[0].id_invoice != null &&
            this.order[0].id_invoice != '' &&
            this.order[0].id_invoice != undefined
          ) {
            this.choiceCompany();
          } else {
            console.log('Picking');
            this.pickingListProducts();
          }
        }
      } catch (err) {
        alert(err);
      }
    },
    async getCountry() {
      try {
        await this.$store.dispatch('fetchCountries', {
          id: this.id,
        });
        this.countries = this.getCommunityCountries;
        this.countries = this.countries.data;
        let comuCountry = this.countries.find(
          (country) => country.code == this.order[0].country
        );
        console.log(comuCountry);
        if (comuCountry == undefined) {
          this.comunitary = false;
        } else {
          this.comunitary = true;
        }
      } catch (err) {
        alert('ERRO' + err);
      }
    },
    async addNote() {
      if (this.note != '') {
        let user = JSON.parse(localStorage.getItem('user'));
        let obj = {
          user: user.username,
          comment: this.note,
        };
        console.log(obj);
        console.log(this.notes);
        this.notes.push(obj);
        try {
          this.id = this.$route.params.id;
          await this.$store.dispatch('addNotes', {
            id: this.id,
            notes: obj,
          });
          this.note = '';
        } catch (err) {
          console.log(err);
          alert(err);
        }
      }
    },

    async getAmostras_fun() {
      try {
        this.id = this.$route.params.id;
        await this.$store.dispatch('getAmostras', {
          order: this.id,
        });
        this.amostras = this.getAmostras;
        console.log('AMOSTRAS', this.amostras);
      } catch (err) {
        console.log(err);
        alert(err);
      }
    },
    async getCodigoOrder() {
      try {
        this.id = this.$route.params.id;
        await this.$store.dispatch('getCodigoOrder', {
          id: this.id,
        });
        this.codigo_order = this.getCodigosOrder;
      } catch (err) {
        console.log(err);
        alert(err);
      }
    },
    async getTaxacao() {
      try {
        const country = this.order[0].country;
        await this.$store.dispatch('getTaxa', {
          country: country,
        });
        this.limitTax = this.getTaxs;
      } catch (err) {
        console.log(err);
        alert(err);
      }
    },

    /* OBTER PRODUTOS DA ENCOMENDA */
    async getProductsFromOrders() {
      try {
        await this.$store.dispatch('fetchOrderProducts');
        this.orderProducts = this.getOrderProducts.data;
      } catch (err) {
        alert(err);
      }
    },
    async AllCountries() {
      try {
        await this.$store.dispatch('fetchAllCountries');
        this.allCountries = this.getAllCountries;
        console.log(this.getAllCountries);
      } catch (err) {
        alert(err);
      }
    },
    async openValidationAddress() {
      console.log(this.order[0].validation_address, this.order[0].status);
      if (
        this.order[0].validation_address == 'logistica' &&
        this.order[0].status == 'packing'
      ) {
        this.addressUpdate = this.order[0].adress1;
        this.cityUpdate = this.order[0].city;
        this.postalCodeUpdate = this.order[0].postcode;
        this.stateUpdate = this.order[0].state;
        this.idOrderUpdate = this.order[0].id;
        this.countryUpdate = this.order[0].country;
        this.emailUpdate = this.order[0].email;
        this.firstName = this.order[0].first_name;
        this.secondName = this.order[0].second_name;
        this.phone = this.order[0].phone;
        this.$refs['modal_address'].show();
      }
    },
    async approveValidationAddress() {
      console.log('Validar Morada');
      try {
        await this.$store.dispatch('approveValidationAddress', {
          id: this.idOrderUpdate,
          address: this.addressUpdate,
          city: this.cityUpdate,
          postalCode: this.postalCodeUpdate,
          state: this.stateUpdate,
          country: this.countryUpdate,
          validateAddress: true,
        });
        console.log('ACABOU');
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'success',
          title: `Morada ${this.idOrderUpdate} Aprovada`,
          showConfirmButton: false,
          timer: 1500,
        });
        this.order[0].adress1 = this.addressUpdate;
        this.order[0].city = this.cityUpdate;
        this.order[0].postcode = this.postalCodeUpdate;
        this.order[0].state = this.stateUpdate;
        this.order[0].country = this.countryUpdate;
        this.$refs['modal_address'].hide();
      } catch (err) {
        console.log(err);
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'error',
          title: err,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    async sendEmailToApproveAddress() {
      console.log('Enviar email');
      try {
        await this.$store.dispatch('sendEmailToApproveAddress', {
          id: this.idOrderUpdate,
          address: this.addressUpdate,
          city: this.cityUpdate,
          postCode: this.postalCodeUpdate,
          state: this.stateUpdate,
          country: this.countryUpdate,
          email: this.emailUpdate,
          firstName: this.firstName,
          secondName: this.secondName,
          phone: this.phone,
        });
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'success',
          title: `Email enviado`,
          showConfirmButton: false,
          timer: 1500,
        });
        this.$refs['modal_address'].hide();
        window.location.reload();
        // this.reload++;
      } catch (err) {
        console.log(err);
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'error',
          title: err,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
  },

  computed: {
    ...mapGetters(['getOrderId']),
    ...mapGetters(['getOrderExpeditionByID']),
    ...mapGetters(['getCommunityCountries']),
    ...mapGetters(['getProducts']),
    ...mapGetters(['getOrderProducts']),
    ...mapGetters(['getFilterOrders']),
    ...mapGetters(['getAmostras']),
    ...mapGetters(['getCodigosOrder']),
    ...mapGetters(['getTaxs']),
    ...mapGetters(['getPickingTrans']),
    ...mapGetters(['getAllCountries']),
  },
};
</script>

<style scoped>
a {
  margin: 25px;
  padding-top: 20px;
  text-decoration: none;
  color: black;
  font-size: 20px;
}
a:hover {
  color: gray;
}

#openArticle {
  color: rgb(0, 98, 255);
  text-align: center;
}
#openArticle:hover {
  text-decoration: underline;
}

img {
  max-width: 100%;
  border-radius: 18px;
}

#comments {
  display: flex;
  justify-content: center;
}

.order {
  margin-top: 30px;
}

textarea {
  resize: none;
}

.card {
  padding: 25px;
  background-color: rgba(255, 147, 147, 0.733);
  margin: 0px 50px 0px 50px;
  border: 1px solid #dddd;
  border-radius: 18px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  text-align: center;
}
#subtitle {
  color: white;
  font-weight: bold;
}
.title {
  text-align: left;
  color: white;
}
.google {
  text-align: center;
  margin-top: 20px;
}
</style>
