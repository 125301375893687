<template>
  <div class="picking">
    <h3>Encomenda nº {{ orderId }}</h3>
    <b-container>
      <b-row class="title" align-v="center">
        <b-col cols="2">Data</b-col>
        <b-col cols="10"> Artigos</b-col>
        <!-- <b-col cols="2"> Validados</b-col> --></b-row
      >
    </b-container>
    <hr />
    <!-- 3 COLUNAS -->
    <b-container>
      <b-row align-v="center">
        <!-- PRIMEIRA COLUNA SURGE A DATA DA ENCOMENDA -->
        <b-col cols="2" class="left">
          <p>{{ this.order.date }}</p>
        </b-col>
        <!-- SEGUNDA COLUNA SURGE A QUANTIDADE DE CADA PRODUTO, O NOME, E O SKU CORRESPONDENTE -->
        <b-col cols="10">
          <span v-for="article in articles" :key="article.id">
            <p>
              <a>{{ article.ordered }}</a> x {{ article.sku }} -
              <a
                id="openArticle"
                v-b-modal.modal-3
                @click="passProductData(article)"
                >{{ article.name }}
              </a>
              - {{ article.price_eur }} € -
              <a v-if="article.picked == article.ordered">(✔️)</a>
              <a v-else style="color: red"
                >(Pick: {{ article.picked }} |<a style="color: black"
                  >| Stock: {{ article.stock }}</a
                >)</a
              >
              <!-- <a v-else style="color:red">({{article.picked}}/{{ article.stock }})</a> -->
            </p></span
          ></b-col
        >
      </b-row>
    </b-container>
    <hr />
    <b-row>
      <b-col cols="2"></b-col>
      <b-col cols="8" style="text-align: center">
        <span v-if="junt_id.length != 0">
          <p><b>Encomendas do mesmo cliente</b></p>
          <label>
            <input type="checkbox" v-model="ordersSeen" />
            Marcar encomendas do mesmo cliente como visto
          </label>
          <div style="border: 3px solid red; border-radius: 5px; padding: 5px">
            <span
              v-for="order in junt_id"
              :key="order"
              id="openArticle"
              v-b-modal.modal_encomenda
              @click="passOrderData1(order)"
              >[{{ order }}]
            </span>
          </div>
        </span>
      </b-col>
      <b-col cols="2">
        <!-- <span v-if="this.picking.length != 0">
          <button
            id="next"
            type="submit"
            class="btn btn-primary mb-2"
            @click="save()"
            cols="2"
          >
            Guardar
          </button></span
        > -->
        <span>
          <button
            v-if="this.order.status == 'packing'"
            id="next"
            type="submit"
            class="btn btn-primary mb-2"
            @click="checkPicking()"
            cols="2"
          >
            Seguinte
          </button></span
        ><span>
          <router-link :to="'/newOrder/' + this.orderId">
            <button
              id="next"
              type="submit"
              class="btn btn-primary mb-2"
              cols="2"
            >
              Juntar Encomenda
            </button>
          </router-link>
          <router-link :to="'/editOrder/' + this.orderId">
            <button
              id="next"
              type="submit"
              class="btn btn-primary mb-2"
              cols="2"
            >
              Editar Encomenda
            </button>
          </router-link>
        </span></b-col
      ></b-row
    >
    <!-- MODAL PARA MOSTRAR O PRODUTO -->
    <b-modal
      ref="modal_product"
      id="modal-3"
      title="Produto"
      size="lg"
      hide-footer
    >
      <div class="card">
        <b-container>
          <b-row align-v="center"
            ><b-col class="left">
              <!-- IMAGEM DO PRODUTO -->
              <img :src="imgProduct" />
            </b-col>
            <b-col>
              <p><span id="subtitle">STOCK: </span> {{ stockProduct }}</p>
              <p><span id="subtitle">EAN:</span> {{ eanProduct }}</p>
              <p><span id="subtitle">SKU:</span> {{ skuProduct }}</p>
              <p><span id="subtitle">HS Code:</span> {{ hsProduct }}</p>
              <p>
                <span id="subtitle">Notas Arm.:</span> {{ warehouseProduct }}
              </p>
              <p><span id="subtitle">Notas:</span> {{ notesProduct }}</p>
              <p><span id="subtitle">Peso:</span> {{ weightProduct }} (g)</p>
            </b-col></b-row
          >
        </b-container>
        <button class="btn btn-info" @click="openProdPage()">
          Editar Produto
        </button>
      </div>
    </b-modal>
    <b-modal
      ref="modal_product"
      id="modal_encomenda"
      :title="'Order ' + this.modal_id"
      size="lg"
      hide-footer
    >
      <div class="card">
        <h5>Artigos</h5>
        <hr />
        <b-container>
          <b-row align-v="center">
            <b-col>
              <p
                style="font-size: medium"
                v-for="article in articles_modal"
                :key="article.sku"
              >
                {{ article.quantity }} x {{ article.sku }} -
                <a
                  style="font-size: medium; margin: 0px"
                  id="openArticle"
                  v-b-modal.modal-juntar
                  @click="passProductData(article)"
                  >{{ article.name }}
                </a>
              </p>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    orderId: String,
    articles: Array,
    order: Object,
    expeditionCreated: Boolean,
    allProducts: Array,
    amostraTotal: Number,
    limitTax: Number,
    junt_id: Array,
    filteredOrders: Array,
  },
  data: function () {
    return {
      picking: [],
      productsPicked: [],
      products: [],
      filterProducts: '',
      newOrder: true,
      disable: true,
      disableSave: true,
      eanProduct: '',
      skuProduct: '',
      hsProduct: '',
      warehouseProduct: '',
      notesProduct: '',
      weightProduct: '',
      imgProduct: '',
      idProduct: '',
      stockProduct: '',
      modal_id: '',
      articles_modal: [],
      wasInternReturn: false,
      ordersSeen: false,
      /* :disabled="disableSave == false"
      :disabled="disable == true" */
    };
  },
  methods: {
    async openProdPage() {
      window.open(
        `https://${window.location.host}/product/${this.idProduct}`,
        '_blank'
      );
      this.$refs['modal_product'].hide();
    },
    /* OBTER PRODUTOS DA ENCOMENDA */
    // async getProductsFromOrders() {
    //   try {
    //     await this.$store.dispatch('fetchOrderProducts');
    //     this.products = this.getOrderProducts.data;
    //   } catch (err) {
    //     alert(err);
    //   }
    //   if (!this.expeditionCreated) {
    //     await this.filterOrder();
    //   }
    // },

    async filterOrder() {
      /* FILTRAS POR ENCOMENDA */
      this.filterProducts = this.products.find(
        (product) => product.id_order == this.orderId
      );
      console.log(this.filterProducts);
      if (this.filterProducts != undefined) {
        /* if (this.filterProducts.picking) {
          console.log("TUDOOOO PICADOOOOO");
          this.$parent.productsOrderList();
        } else { */
        this.newOrder = false;
        for (let i = 0; i < this.articles.length; i++) {
          let x = this.filterProducts.products.includes(this.articles[i].sku);
          if (x) {
            this.picking.push(this.articles[i].sku);
          }
          console.log('FOR ARTICLES: ', this.articles);
        }
        console.log('PICKING: ', this.filterProducts.products.length);
        console.log('ARTIGOS: ', this.articles.length);
        if (this.filterProducts.products.length != 0) {
          console.log('TUDOOOO PICADOOOOO');
          this.$parent.productsOrderList();
        } /*  */
        /*  } */
      } else {
        this.newOrder = true;
        console.log(this.articles);
        /* for (let i = 0; i < this.articles.length; i++) {
          console.log(this.articles[i].quantity)
          for (let j = 0; j < this.articles[i].quantity; j++) {
            console.log("ENTROU")
            this.picking.push(this.articles[i].sku);
          }
        } */
        this.addProducts();
      }
    },
    /* ADICIONAR PRODUTO COMO FEITO O PICKING */
    addProducts() {
      this.productsPicked = [];
      let cont = 0;
      let quantity;
      let sku;
      for (let i = 0; i < this.articles.length; i++) {
        cont = 0;
        quantity = parseInt(this.articles[i].quantity);
        sku = this.articles[i].sku;
        for (let j = 0; j < this.picking.length; j++) {
          if (this.articles[i].sku == this.picking[j]) {
            for (let k = 0; k < quantity; k++) {
              cont = cont + 1;
              this.productsPicked.push(sku);
            }
          }
        }
      }
      if (this.picking.length == this.articles.length) {
        console.log();
        this.disable = false;
      } else {
        this.disable = true;
      }
      console.log(this.productsPicked);
    },
    /* GUARDAR O PICKING */
    // save() {
    //   if (this.newOrder == false) {
    //     this.updateProducts();
    //   } else if (this.newOrder == true && this.disable == false) {
    //     this.addToBD(true);
    //   } else {
    //     this.addToBD(false);
    //   }
    // },
    async checkPicking() {
      console.log(this.order);
      console.log('PICKING ', this.order.picking_done);
      if (this.order.send_email_address) {
        this.$swal({
          title: 'Verifique a morada antes de aceder à encomenda',
          icon: 'warning',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Ok!',
        });
      } else if (this.order.cs_lock) {
        this.$swal({
          title: 'A encomenda encontra-se bloqueada pelo CS!',
          icon: 'warning',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Vou verificar!',
        });
      } else if (
        !this.order.picking_done &&
        (this.order.id_invoice == null ||
          this.order.id_invoice == '' ||
          this.order.id_invoice == undefined)
      ) {
        this.$swal({
          title:
            'O picking da encomenda ainda não está completo nem existe fatura gerada!',
          icon: 'warning',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Vou verificar!',
        });
      } else if (this.junt_id.length > 0 && !this.ordersSeen) {
        this.$swal({
          title:
            'Você deve marcar as encomendas do mesmo cliente como visto para continuar!',
          icon: 'warning',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Vou verificar!',
        });
      } else {
        this.confirmModal();
      }
    },
    async confirmModal() {
      if (this.limitTax < this.amostraTotal) {
        this.$swal({
          title:
            'O valor da encomenda passa o limite de taxação, deseja continuar?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim, continuar!',
          cancelButtonText: 'Cancelar',
        }).then(async (result) => {
          if (result.isConfirmed) {
            await this.$swal({
              title:
                'Insira o código de confirmação para criação de nova encomenda',
              html: `<input type="password" id="numberInvoice" class="form-control" v-model="teste" placeholder="Código">`,
              confirmButtonText: 'Confirmar',
              cancelButtonText: 'Cancelar',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              focusConfirm: false,
              preConfirm: () => {
                const code = this.$swal
                  .getPopup()
                  .querySelector('#numberInvoice').value;
                if (!code) {
                  this.$swal.showValidationMessage(
                    `Por favor preencha os campos corretamente`
                  );
                }
                return { code: code };
              },
            }).then(async (result2) => {
              if (result2.isConfirmed) {
                if (result2.value.code != 'confirmo') {
                  await this.$swal({
                    icon: 'error',
                    title: 'Código de confirmação inválido',
                    text: 'Encomenda não criada',
                  });
                } else {
                  this.next();
                }
              }
            });
          }
        });
      } else if (this.wasInternReturn) {
        this.$swal({
          title:
            'Esta encomenda já foi devolvida internamente, e mudança de morada foi efectuada!',
          text: 'Verifique a morada da fatura e caso tenha alterado efetua uma conta de crédito!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Continuar!',
          cancelButtonText: 'Cancelar',
        }).then((result) => {
          if (result.isConfirmed) {
            this.next();
          }
        });
      } else {
        this.next();
      }
    },
    /* AVANÇAR NO FORMULÁRIO */
    async next() {
      for (let i = 0; i < this.articles.length; i++) {
        console.log(this.articles[i].quantity);
        for (let j = 0; j < this.articles[i].quantity; j++) {
          console.log('ENTROU');
          this.picking.push(this.articles[i].sku);
        }
      }
      this.$parent.productsOrderList();
      // this.$root.$emit("component1");
    },

    /* ATUALIZAR PRODUTOS */
    // async updateProducts() {
    //   try {
    //     await this.$store.dispatch("putOrderProducts", {
    //       id: this.orderId,
    //       products: this.productsPicked,
    //       picking: !this.disable,
    //     });
    //     this.$swal({
    //       toast: true,
    //       position: "top-end",
    //       icon: "success",
    //       title: "Produtos Guardados",
    //       showConfirmButton: false,
    //       timer: 1500,
    //     });
    //   } catch (err) {
    //     alert(err);
    //   }
    // },

    /* ADICIONAR OS PRODUTOS DA ENCOMENDA À BD */
    // async addToBD(disable) {
    //   try {
    //     await this.$store.dispatch("addOrderProducts", {
    //       id: this.orderId,
    //       products: this.productsPicked,
    //       picking: disable,
    //     });
    //   } catch (err) {
    //     alert(err);
    //   }
    // },

    // DADOS DA MODAL DO PRODUTO
    async passProductData(data) {
      // console.log('TODOS OS PRODUTOS: ', this.allProducts);
      // const filterProduct = this.allProducts.filter((product) => product.sku.includes(data.sku));
      this.eanProduct = data.ean;
      this.skuProduct = data.sku;
      this.hsProduct = data.hscode;
      this.warehouseProduct = data.note_warehouse;
      this.notesProduct = data.note_finish;
      this.weightProduct = data.weight;
      this.imgProduct = data.img;
      this.idProduct = data.id_backoffice;
      this.stockProduct = data.stock;
      // console.log(filterProduct, data);
    },
    async passOrderData1(id) {
      const filterOrder = this.filteredOrders.filter((order) => order.id == id);
      console.log('FILTER ORDER: ', filterOrder);
      this.modal_id = filterOrder[0].id;
      this.articles_modal = JSON.parse(filterOrder[0].articles);
    },
    async checkInternReturn() {
      console.log('Check Intern Return');
      try {
        await this.$store.dispatch('checkIfWasInternReturn', {
          id: this.orderId,
        });
        this.wasInternReturn = this.getcheckIfWasInternReturn;
        console.log('Was returning: ', this.wasInternReturn);
      } catch (err) {
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'error',
          title: err,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
  },
  computed: {
    ...mapGetters(['getOrderProducts']),
    ...mapGetters(['getcheckIfWasInternReturn']),
  },
  async created() {
    await this.filterOrder();
    await this.checkInternReturn();
    console.log(this.order);
    console.log('ASDAS', this.articles);
  },
};
</script>
<style scoped>
.picking {
  padding: 25px;
  background-color: rgba(255, 147, 147, 0.733);
  margin: 0px 50px 0px 50px;
  border: 1px solid #dddd;
  border-radius: 18px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
h3 {
  color: white;
  margin-bottom: 20px;
}
p {
  text-align: center;
}
hr {
  background-color: white;
  height: 2px;
  border: 0;
}
button {
  background-color: rgb(104, 155, 75);
  border: none;
  margin-right: 5px;
}
button:hover {
  background-color: rgba(104, 155, 75, 0.788);
  border: none;
  box-shadow: none;
}
button:focus {
  background-color: rgb(104, 155, 75);
  border: none;
  box-shadow: none;
}
img {
  max-width: 100%;
  border-radius: 18px;
}
.card {
  padding: 25px;
  background-color: rgba(255, 147, 147, 0.733);
  margin: 0px 50px 0px 50px;
  border: 1px solid #dddd;
  border-radius: 18px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
#subtitle {
  color: white;
  font-weight: bold;
}
#openArticle {
  color: rgb(0, 98, 255);
}
#openArticle:hover {
  text-decoration: underline;
}
.title {
  text-align: center;
}
</style>
